import React from 'react'

import {
  createBrowserRouter,
  redirect,
  RouterProvider
} from 'react-router-dom'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { TeacherProvider } from '../context/teacher/index'

import { createTheme, ThemeProvider } from '@mui/material'

import * as CONSTANTS from '../config/constants'
import * as CONFIG from '../config'

import Logic from './logic'

import Home from 'src/views/home'
import Login from 'src/views/login'
import RegistrationConfirmation from 'src/views/registration-confirmation'
import ResetPassword from 'src/views/reset-password'

import LessonPlan from 'src/views/lesson-plan'
import StudentReport from 'src/views/student-report'
import Worksheet from 'src/views/worksheet'

import * as analytics from '../modules/analytics'
import NotFoundPage from 'src/views/not-found'

analytics.init()

const isLogged = window.localStorage.getItem(CONSTANTS.LOCAL_STORAGE.JWT) !== null

const router = createBrowserRouter([{
  path: `/`,
  loader: () => isLogged ? null : redirect(`/login`),
  element: <Home />
}, {
  path: `/login`,
  loader: () => isLogged ? redirect(`/`) : null,
  element: <Login />
}, {
  path: `/registration-confirmation`,
  element: <RegistrationConfirmation />
}, {
  path: `/reset-password`,
  element: <ResetPassword />
}, {
  path: `/lesson-plans/:lessonPlanId?`,
  loader: () => isLogged ? null : redirect(`/login`),
  element: <LessonPlan />
}, {
  path: `/student-reports/:studentReportId?`,
  loader: () => isLogged ? null : redirect(`/login`),
  element: <StudentReport />
}, {
  path: `/worksheets/:worksheetId?`,
  loader: () => isLogged ? null : redirect(`/login`),
  element: <Worksheet />
}, {
  path: `/not-found`,
  element: <NotFoundPage />
}])

function App () {
  const theme = createTheme({
    typography: {
      fontFamily: `Inter, sans-serif`
    },
    palette: {
      primary: {
        main: `#0EAA57`
      },
      secondary: {
        main: `#7000FF`
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={CONFIG.GOOGLE_AUTH_CLIENT_ID}>
        <TeacherProvider>
          <Logic>
            <RouterProvider router={router} />
          </Logic>
        </TeacherProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  )
}

export default App
