import React from 'react'

import { Box } from '@mui/material'
import LogoSmall from 'src/components/logo-small'

import LogoutIcon from 'src/assets/sidebar/logout.png'
import { useNavigate } from 'react-router-dom'

interface IProps {
  drawerWidth: number
}

const PADDING = 30

export default function SideBar (props: IProps) {
  const navigate = useNavigate()

  function onLogout () {
    localStorage.clear()
    location.assign(`/`)
  }

  return (
    <Box
      sx={{
        position: `fixed`,
        height: `calc(100vh - ${PADDING * 2}px)`,
        width: props.drawerWidth - PADDING * 2,
        backgroundColor: `#FEFEFE`,
        padding: `${PADDING}px`,

        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`
      }}
    >
      <Box
        sx={{ cursor: `pointer` }}
        onClick={() => navigate(`/`)}
      >
        <LogoSmall />
      </Box>

      <Box
        sx={{ flexGrow: 1 }}
      >

      </Box>

      <Box
        sx={{ cursor: `pointer` }}
        onClick={onLogout}
      >
        <img src={LogoutIcon}></img>
      </Box>

    </Box>
  )
}
