import React from 'react'

import { Box, Button, Typography } from '@mui/material'
import Logo from 'src/components/logo'

import LogoutIcon from 'src/assets/sidebar/logout.png'
import StarBorderIcon from '@mui/icons-material/StarBorder'

interface IProps {
  drawerWidth: number
}

const PADDING = 30

export default function SideBar (props: IProps) {
  function onLogout () {
    localStorage.clear()
    location.assign(`/`)
  }

  return (
    <Box
      sx={{
        position: `fixed`,
        height: `calc(100vh - ${PADDING * 2}px)`,
        width: props.drawerWidth - PADDING * 2,
        backgroundColor: `#FEFEFE`,
        padding: `${PADDING}px`,

        display: `flex`,
        flexDirection: `column`
      }}
    >
      <Logo />

      <Box sx={{ flexGrow: 1 }} >
        <Box sx={{ marginTop: `4px`, backgroundColor: `#d0fbe4`, borderRadius: `8px`, display: `flex`, justifyContent: `center`, gap: `8px`, alignItems: `center` }}>
          <StarBorderIcon />
          <Typography fontWeight="300">
            Versión de prueba
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box sx={{ marginBottom: `35px` }}>
          <a href='https://mdeda1a0xpt.typeform.com/to/xO0Offxy' target='_blank'>
            <Button fullWidth variant='contained'>Dame tu opinión</Button>
          </a>
        </Box>

        <Box
          sx={{ display: `flex`, flexDirection: `row`, gap: `8px`, cursor: `pointer` }}
          onClick={onLogout}
        >
          <img src={LogoutIcon}></img>
          <Typography color="#EB5757" fontSize="18px" fontWeight="500">
            Logout
          </Typography>
        </Box>

      </Box>

    </Box>
  )
}
