
import React from "react"

import { Box } from '@mui/material'
import { STUDENT_REPORT_STEP, SCHOOL_GRADE } from "@instruia/utils"

import NewReport from "./new-report"
import ExecutedReport from "./executed-report"
import Header from '../../../components/feature/header'
import ReporteDeClaseIcon from 'src/assets/home/boletin_de_calificacion_icon.png'
import { useIsMobile } from "src/modules/utils"

interface IProps {
  isLoading: boolean
  uiStep: STUDENT_REPORT_STEP
  setUIStep: (s: STUDENT_REPORT_STEP) => void
  onBack: () => void
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
  pronouns: string
  setPronouns: (s: string) => void
  strengths: string
  setStrengths: (s: string) => void
  opportunities: string
  setOpportunities: (o: string) => void
  onContinueStep: () => void
  onSkipStep: () => void
  hasExecuted: boolean
  onEditStudentReportClicked: () => void
}

export default function ReportEditor (props: IProps) {
  function getPercentage () {
    if (props.uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1) return 20
    if (props.uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2) return 40
    if (props.uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3) return 60
    if (props.uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4) return 80
    if (props.uiStep === STUDENT_REPORT_STEP.DONE_5) return 100

    return 100
  }

  const isMobile = useIsMobile()
  const percentage = getPercentage()
  const isDone = props.uiStep === STUDENT_REPORT_STEP.DONE_5

  return (
    <Box sx={{ flexGrow: 1, display: `flex`, flexDirection: `column`, padding: isMobile ? `0 0 25px 0px` : `25px`, gap: `25px`, maxWidth: `800px` }} >
      <Header
        percentage={percentage}
        isDone={isDone}
        shouldDisplayBackButton={props.uiStep !== STUDENT_REPORT_STEP.SELECT_GRADE_1 && props.uiStep !== STUDENT_REPORT_STEP.DONE_5}
        onBack={props.onBack}
        isLoading={props.isLoading}
        icon={ReporteDeClaseIcon}
        title={`Reporte del estudiante`}
        subtitle={`Escribe en segundos el reporte de actitudes, fortalezas y oportunidades del estudiante`}
      />
      {props.hasExecuted
        ? (
          <ExecutedReport
            isLoading={props.isLoading}
            grade={props.grade}
            setGrade={props.setGrade}
            pronouns={props.pronouns}
            setPronouns={props.setPronouns}
            strengths={props.strengths}
            setStrengths={props.setStrengths}
            opportunities={props.opportunities}
            setOpportunities={props.setOpportunities}
            onEditStudentReportClicked={props.onEditStudentReportClicked}
          />
          )
        : (
          <NewReport
            isLoading={props.isLoading}
            uiStep={props.uiStep}
            setUIStep={props.setUIStep}
            grade={props.grade}
            setGrade={props.setGrade}
            pronouns={props.pronouns}
            setPronouns={props.setPronouns}
            strengths={props.strengths}
            setStrengths={props.setStrengths}
            opportunities={props.opportunities}
            setOpportunities={props.setOpportunities}
            onContinueStep={props.onContinueStep}
            onSkipStep={props.onSkipStep}
          />
          )}

    </Box>
  )
}
