
import axios from '../../../modules/axios'
import { IEnterResponse } from '../types'

export async function loginWithEmail (email: string, password: string): Promise<IEnterResponse> {
  const response = await axios({
    method: `post`,
    url: `/teachers/login-with-email`,
    data: {
      email,
      password
    }
  })

  return response.data
}

export async function loginWithToken () : Promise<IEnterResponse> {
  const response = await axios({
    method: `post`,
    url: `/teachers/login-with-token`
  })

  return response.data
}

export async function registerWithEmail (registeringEmail: string, registeringPassword: string, registeringFirstName: string, registeringLastName: string) {
  await axios({
    method: `post`,
    url: `/teachers/register-with-email`,
    data: {
      registeringEmail,
      registeringPassword,
      registeringFirstName,
      registeringLastName
    }
  })
}

export async function confirmRegistration (code: string): Promise<void> {
  await axios({
    method: `post`,
    url: `/teachers/registration-confirmation`,
    data: {
      code
    }
  })
}

export async function enterWithGoogle (googleToken: string) : Promise<IEnterResponse> {
  const response = await axios({
    method: `post`,
    url: `/teachers/enter-with-google-token`,
    data: {
      googleToken
    }
  })

  return response.data
}

export async function recoverPassword (recoveringEmail: string): Promise<void> {
  await axios({
    method: `post`,
    url: `/teachers/recover-password`,
    data: {
      recoveringEmail
    }
  })
}

export async function resetPassword (code: string, newPassword: string): Promise<void> {
  await axios({
    method: `post`,
    url: `/teachers/reset-password`,
    data: {
      code,
      newPassword
    }
  })
}
