
import { LESSON_PLAN_EXECUTION_FEEDBACK, SCHOOL_GRADE, SCHOOL_SUBJECT } from '@instruia/utils'
import axios from '../../../../modules/axios'
import { ILessonPlanExecution } from 'src/entities/lesson-plan/execution/types'
import { IAggregatedLessonPlan } from 'src/entities/lesson-plan/types'
import { ILessonPlanExecutionChatMessageExecution } from 'src/entities/lesson-plan/execution/chat-message/execution/types'
import { ILessonPlanExecutionChatMessage } from 'src/entities/lesson-plan/execution/chat-message/types'

export async function setLessonPlanExecutionFeedback (
  lessonPlanId: string,
  lessonPlanExecutionId: string,
  feedback: LESSON_PLAN_EXECUTION_FEEDBACK
): Promise<ILessonPlanExecution> {
  const response = await axios({
    method: `patch`,
    url: `/lesson-plans/${lessonPlanId}/executions/${lessonPlanExecutionId}/feedback`,
    data: {
      feedback
    }
  })

  return response.data
}

export async function regenerateLessonPlanExecution (
  lessonPlanId: string,
  lessonPlanExecutionId: string,
  subject: SCHOOL_SUBJECT | null,
  theme: string | null,
  grade: SCHOOL_GRADE | null,
  additionalDetails: string | null
): Promise<IAggregatedLessonPlan> {
  const response = await axios({
    method: `post`,
    url: `/lesson-plans/${lessonPlanId}/executions/${lessonPlanExecutionId}/regenerate`,
    data: {
      subject,
      theme,
      grade,
      additionalDetails
    }
  })

  return response.data
}

export async function sendLessonPlanExecutionMessage (
  lessonPlanId: string,
  lessonPlanExecutionId: string,
  content: string
): Promise<IAggregatedLessonPlan> {
  const response = await axios({
    method: `post`,
    url: `/lesson-plans/${lessonPlanId}/executions/${lessonPlanExecutionId}/chat-messages`,
    data: {
      content
    }
  })

  return response.data
}

export async function getLessonPlanCurrentExecutionLastChatMessageExecution (
  lessonPlanId: string,
  lessonPlanExecutionId: string
): Promise<ILessonPlanExecutionChatMessageExecution> {
  const response = await axios({
    method: `get`,
    url: `/lesson-plans/${lessonPlanId}/executions/${lessonPlanExecutionId}/chat-messages/executions/last`
  })

  return response.data
}

export async function getLessonPlanCurrentExecutionChatMessages (
  lessonPlanId: string,
  lessonPlanExecutionId: string
): Promise<ILessonPlanExecutionChatMessage[]> {
  const response = await axios({
    method: `get`,
    url: `/lesson-plans/${lessonPlanId}/executions/${lessonPlanExecutionId}/chat-messages`
  })

  return response.data
}
