import React from 'react'

import { SCHOOL_GRADE } from '@instruia/utils'

import EditExecutionModal from 'src/components/feature/edit-execution-modal'

import DescribeThemeStep1 from '../lesson-editor/steps/1-describe-theme'
import SelectGradeStep2 from '../lesson-editor/steps/2-select-grade'
import SelectExerciseCountStep3 from '../lesson-editor/steps/3-select-exercise-count'

interface IProps {
  isLoading: boolean
  isEditWorksheetModalOpen: boolean
  setIsEditWorksheetModalOpen: (c: boolean) => void
  editedExecutionTheme: string
  setEditedExecutionTheme: (t: string) => void
  editedExecutionGrade: SCHOOL_GRADE | null
  setEditedExecutionGrade: (g: SCHOOL_GRADE) => void
  editedExecutionExerciseCount: number
  setEditedExecutionExerciseCount: (c: number) => void
  theme: string
  grade: SCHOOL_GRADE | null
  exerciseCount: number
  setExerciseCount: (c: number) => void
  onSubmitChanges: () => void
}

export default function EditLessonPlanModal (props: IProps) {
  function getIsButtonDisabled (): boolean {
    if (props.isLoading) return true

    if (props.editedExecutionTheme !== props.theme) return false
    if (props.editedExecutionGrade !== props.grade) return false
    if (props.editedExecutionExerciseCount !== props.exerciseCount) return false

    return true
  }

  const isButtonDisabled = getIsButtonDisabled()

  return (
    <EditExecutionModal
      title="Crear ejercicios"
      isButtonDisabled={isButtonDisabled}
      isEditExecutionModalOpen={props.isEditWorksheetModalOpen}
      setIsEditExecutionModalOpen={props.setIsEditWorksheetModalOpen}
      onSubmitChanges={props.onSubmitChanges}
    >
      <DescribeThemeStep1
        isLoading={props.isLoading}
        theme={props.editedExecutionTheme}
        setTheme={props.setEditedExecutionTheme}
      />
      <SelectGradeStep2
        isDisabled={props.isLoading}
        grade={props.editedExecutionGrade}
        setGrade={props.setEditedExecutionGrade}
      />
      <SelectExerciseCountStep3
        isLoading={props.isLoading}
        exerciseCount={props.editedExecutionExerciseCount}
        setExerciseCount={props.setEditedExecutionExerciseCount}
      />
    </EditExecutionModal>
  )
}
