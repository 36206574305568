import React, { useState, useEffect } from 'react'

import { useTheme } from '@mui/material/styles'
import { Snackbar, useMediaQuery } from '@mui/material'
import { useTeacherState } from 'src/context/teacher'

import { STUDENT_REPORT_STEP, STUDENT_REPORT_EXECUTION_STATUS, ERROR_CODE, STUDENT_REPORT_EXECUTION_FEEDBACK, STUDENT_REPORT_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS, SCHOOL_GRADE } from '@instruia/utils'

import * as studentReportService from 'src/entities/student-report/service'
import { useNavigate, useParams } from 'react-router-dom'
import { IStudentReport } from 'src/entities/student-report/types'

import ReportEditor from './report-editor'
import ExecutionErrorModal from './execution-error-modal'

import { markdownToTxt } from 'markdown-to-txt'
import { ExecutionView } from './execution-view'

import * as studentReportExecutionService from 'src/entities/student-report/execution/service'
import EditStudentReportModal from './edit-student-report-modal'
import { IStudentReportExecutionChatMessage } from 'src/entities/student-report/execution/chat-message/types'
import { IStudentReportExecutionChatMessageExecution } from 'src/entities/student-report/execution/chat-message/execution/types'

import * as analytics from '../../modules/analytics'
import { ANALYTICS_EVENTS } from 'src/modules/analytics/analytics-events'
import { IStudentReportExecution } from 'src/entities/student-report/execution/types'

import FeatureView from 'src/components/feature/view'

export default function LessonPlan () {
  const params = useParams()
  const navigate = useNavigate()
  const stylesTheme = useTheme()
  const teacherState = useTeacherState()

  // eslint-disable-next-line dot-notation
  const paramsStudentReportId = params[`studentReportId`] || null

  const doesItHaveSidebar = useMediaQuery(stylesTheme.breakpoints.up(`md`))

  const [studentReportId, setStudentReportId] = useState<string | null>(null)
  const [_isLoading, setIsLoading] = useState(false)

  const [uiStep, setUIStep] = useState<STUDENT_REPORT_STEP>(STUDENT_REPORT_STEP.SELECT_GRADE_1)
  const [studentReportStep, setStudentReportStep] = useState<STUDENT_REPORT_STEP>(STUDENT_REPORT_STEP.SELECT_GRADE_1)

  const [grade, setGrade] = useState<SCHOOL_GRADE | null>(null)
  const [pronouns, setPronouns] = useState(``)
  const [strengths, setStrengths] = useState(``)
  const [opportunities, setOpportunities] = useState(``)

  const [executionId, setExecutionId] = useState<string | null>(null)
  const [executionGrade, setExecutionGrade] = useState<SCHOOL_GRADE | null>(null)
  const [executionPronouns, setExecutionPronouns] = useState(``)
  const [executionStrengths, setExecutionStrengths] = useState(``)
  const [executionOpportunities, setExecutionOpportunities] = useState(``)
  const [executionResult, setExecutionResult] = useState<string | null>(null)
  const [executionStatus, setExecutionStatus] = useState<STUDENT_REPORT_EXECUTION_STATUS | null>(null)
  const [executionFeedback, setExecutionFeedback] = useState<STUDENT_REPORT_EXECUTION_FEEDBACK>(STUDENT_REPORT_EXECUTION_FEEDBACK.NONE)

  const [executionErrorCode, setExecutionErrorCode] = useState<ERROR_CODE.TEACHER__EXECUTE_STUDENT_REPORT__MAX_QUOTA_REACHED | null>(null)
  const [isCopiedToClipboardSnackbarOpen, setIsCopiedToClipboardSnackbarOpen] = useState(false)

  const [isEditStudentReportModalOpen, setIsEditStudentReportModalOpen] = useState(false)

  const [editedExecutionGrade, setEditedExecutionGrade] = useState<SCHOOL_GRADE | null>(null)
  const [editedExecutionPronouns, setEditedExecutionPronouns] = useState(``)
  const [editedExecutionOpportunities, setEditedExecutionOpportunities] = useState(``)
  const [editedExecutionStrenghts, setEditedExecutionStrengths] = useState(``)

  const [chatMessageExecutionStatus, setChatMessageExecutionStatus] = useState<STUDENT_REPORT_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS | null>(null)
  const [chatMessages, setChatMessages] = useState<IStudentReportExecutionChatMessage[]>([])
  const [chatMessageContent, setChatMessageContent] = useState(``)

  const drawerWidth = doesItHaveSidebar ? 100 : 0

  const teacher = teacherState.teacher!

  const hasExecuted = executionStatus !== null &&
    [STUDENT_REPORT_EXECUTION_STATUS.COMPLETED, STUDENT_REPORT_EXECUTION_STATUS.FAILED].includes(executionStatus)

  const isLoading = _isLoading || (
    executionStatus !== null &&
    [STUDENT_REPORT_EXECUTION_STATUS.QUEUED, STUDENT_REPORT_EXECUTION_STATUS.RUNNING].includes(executionStatus)
  )

  useEffect(() => {
    fetchStudentReport()
  }, [])

  async function fetchStudentReport () {
    if (paramsStudentReportId === null) return

    setIsLoading(true)
    try {
      const fetchedAggregatedStudentReport = await studentReportService.getStudentReport(paramsStudentReportId)
      await setStudentReportAndPollExecution(
        fetchedAggregatedStudentReport.studentReport,
        fetchedAggregatedStudentReport.studentReportCurrentExecution,
        fetchedAggregatedStudentReport.studentReportCurrentExecutionErrorCode,
        fetchedAggregatedStudentReport.studentReportCurrentExecutionLastChatMessageExecution,
        fetchedAggregatedStudentReport.studentReportCurrentExecutionChatMessages
      )

      setIsLoading(false)
    } catch (e: any) {
      if (e.response && e.response.data) {
        const code = e.response.data.code

        if (code === ERROR_CODE.ENTITY_NOT_FOUND) {
          navigate(`/not-found`)
          return
        }
      }
      console.error(`unhandled error`, e)
    }
  }

  async function createOrEditStudentReport () {
    setIsLoading(true)

    if (studentReportId === null) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_START)

      const newStudentReport = await studentReportService.createStudentReport(grade, pronouns, strengths, opportunities, STUDENT_REPORT_STEP.SET_PRONOUNS_2)
      navigate(`/student-reports/${newStudentReport._id}`)

      await setStudentReportAndPollExecution(newStudentReport, null, null, null, [])

      setIsLoading(false)
      return
    }

    function getStepToUpdate (): STUDENT_REPORT_STEP | null {
      if (uiStep !== studentReportStep) return null

      if (uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1) return STUDENT_REPORT_STEP.SET_PRONOUNS_2
      if (uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2) return STUDENT_REPORT_STEP.SET_STRENGTHS_3
      if (uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3) return STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4
      if (uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4) return STUDENT_REPORT_STEP.DONE_5

      return null
    }

    const step = getStepToUpdate()
    const shouldExecute = step === STUDENT_REPORT_STEP.DONE_5

    if (shouldExecute) analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_EXECUTE)

    const updatedAggregatedStudentReport = await studentReportService.updateStudentReport(studentReportId, grade, pronouns, strengths, opportunities, step, shouldExecute)

    await setStudentReportAndPollExecution(
      updatedAggregatedStudentReport.studentReport,
      updatedAggregatedStudentReport.studentReportCurrentExecution,
      updatedAggregatedStudentReport.studentReportCurrentExecutionErrorCode,
      null,
      []
    )

    setIsLoading(false)
  }

  async function setStudentReportAndPollExecution (
    studentReport: IStudentReport,
    currentExecution: IStudentReportExecution | null,
    executionErrorCode: ERROR_CODE.TEACHER__EXECUTE_STUDENT_REPORT__MAX_QUOTA_REACHED | null,
    chatMessageExecution: IStudentReportExecutionChatMessageExecution | null,
    chatMessages: IStudentReportExecutionChatMessage[]
  ) {
    setStudentReportId(studentReport._id)
    setUIStep(studentReport.step)
    setStudentReportStep(studentReport.step)
    setGrade(studentReport.grade)
    setPronouns(studentReport.pronouns ?? ``)
    setStrengths(studentReport.strengths ?? ``)
    setOpportunities(studentReport.opportunities ?? ``)

    setExecutionErrorCode(executionErrorCode)

    if (currentExecution === null) {
      setExecutionId(null)
      setExecutionGrade(null)
      setExecutionPronouns(``)
      setExecutionStrengths(``)
      setExecutionOpportunities(``)
      setExecutionStatus(null)
      setExecutionResult(null)
      setExecutionFeedback(STUDENT_REPORT_EXECUTION_FEEDBACK.NONE)

      setEditedExecutionGrade(null)
      setEditedExecutionPronouns(``)
      setEditedExecutionStrengths(``)
      setEditedExecutionOpportunities(``)

      setChatMessageExecutionStatus(null)
      setChatMessages([])
      return
    }

    setExecutionId(currentExecution._id)
    setExecutionGrade(currentExecution.grade)
    setExecutionPronouns(currentExecution.pronouns ?? ``)
    setExecutionStrengths(currentExecution.strengths ?? ``)
    setExecutionOpportunities(currentExecution.opportunities ?? ``)
    setExecutionStatus(currentExecution.status)
    setExecutionResult(currentExecution.resultStr)
    setExecutionFeedback(currentExecution.feedback)

    setEditedExecutionGrade(currentExecution.grade)
    setEditedExecutionPronouns(currentExecution.pronouns ?? ``)
    setEditedExecutionStrengths(currentExecution.strengths ?? ``)
    setEditedExecutionOpportunities(currentExecution.opportunities ?? ``)

    let shouldContinue1 = ![STUDENT_REPORT_EXECUTION_STATUS.FAILED, STUDENT_REPORT_EXECUTION_STATUS.COMPLETED].includes(currentExecution.status)
    while (shouldContinue1) {
      await new Promise(resolve => setTimeout(resolve, 2000))

      const exec = await studentReportService.getStudentReportCurrentExecution(studentReport._id)

      if ([STUDENT_REPORT_EXECUTION_STATUS.FAILED, STUDENT_REPORT_EXECUTION_STATUS.COMPLETED].includes(exec.status)) {
        shouldContinue1 = false

        setExecutionGrade(exec.grade)
        setExecutionPronouns(exec.pronouns ?? ``)
        setExecutionStrengths(exec.opportunities ?? ``)
        setExecutionOpportunities(exec.strengths ?? ``)
        setExecutionStatus(exec.status)
        setExecutionResult(exec.resultStr)
        setExecutionFeedback(exec.feedback)

        setEditedExecutionGrade(exec.grade)
        setEditedExecutionPronouns(exec.pronouns ?? ``)
        setEditedExecutionStrengths(exec.strengths ?? ``)
        setEditedExecutionOpportunities(exec.opportunities ?? ``)
      }
    }

    setChatMessages(chatMessages)
    if (chatMessageExecution === null) setChatMessageExecutionStatus(null)
    else {
      setChatMessageExecutionStatus(chatMessageExecution.status)

      let shouldContinue2 = ![STUDENT_REPORT_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.FAILED, STUDENT_REPORT_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.COMPLETED].includes(chatMessageExecution.status)
      while (shouldContinue2) {
        await new Promise(resolve => setTimeout(resolve, 2000))

        const exec = await studentReportExecutionService.getStudentReportCurrentExecutionLastChatMessageExecution(studentReport._id, currentExecution._id)

        if ([STUDENT_REPORT_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.FAILED, STUDENT_REPORT_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.COMPLETED].includes(exec.status)) {
          shouldContinue2 = false
          setChatMessageExecutionStatus(exec.status)
        }
      }

      const newChatMessages = await studentReportExecutionService.getStudentReportCurrentExecutionChatMessages(studentReport._id, currentExecution._id)
      setChatMessages(newChatMessages)
    }
  }

  async function onContinueStep () {
    await createOrEditStudentReport()

    if (uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SET_GRADE)
      setUIStep(STUDENT_REPORT_STEP.SET_PRONOUNS_2)
      return
    }

    if (uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SET_PRONOUNS)
      setUIStep(STUDENT_REPORT_STEP.SET_STRENGTHS_3)
      return
    }

    if (uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SET_STRENGTHS)
      setUIStep(STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4)
    }

    if (uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SET_OPPORTUNITIES)
      setUIStep(STUDENT_REPORT_STEP.DONE_5)
    }
  }

  async function onSkipStep () {
    if (uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SKIPPED_GRADE)
      setUIStep(STUDENT_REPORT_STEP.SET_PRONOUNS_2)
      return
    }

    if (uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SKIPPED_PRONOUNS)
      setUIStep(STUDENT_REPORT_STEP.SET_PRONOUNS_2)
      return
    }

    if (uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SKIPPED_STRENGTHS)
      setUIStep(STUDENT_REPORT_STEP.SET_STRENGTHS_3)
      return
    }

    if (uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_SKIPPED_OPPORTUNITIES)
      await createOrEditStudentReport()
    }
  }

  function onBack () {
    analytics.track(ANALYTICS_EVENTS.FEATURE_STUDENT_REPORT_BACK)

    if (uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2) {
      setUIStep(STUDENT_REPORT_STEP.SELECT_GRADE_1)
      return
    }
    if (uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3) {
      setUIStep(STUDENT_REPORT_STEP.SET_PRONOUNS_2)
      return
    }
    if (uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4) {
      setUIStep(STUDENT_REPORT_STEP.SET_STRENGTHS_3)
    }
  }

  async function onCopyToClipboard () {
    if (executionResult === null) return
    const text = markdownToTxt(executionResult)
    navigator.clipboard.writeText(text)
    setIsCopiedToClipboardSnackbarOpen(true)

    setTimeout(() => setIsCopiedToClipboardSnackbarOpen(false), 2000)
  }

  async function onFeedbackGiven (feedback: STUDENT_REPORT_EXECUTION_FEEDBACK) {
    if (studentReportId === null || executionId === null) return

    const initialFeedback = executionFeedback
    const feedbackToSet = feedback === executionFeedback ? STUDENT_REPORT_EXECUTION_FEEDBACK.NONE : feedback
    setExecutionFeedback(feedbackToSet)

    try {
      const updatedExecution = await studentReportExecutionService.setStudentReportExecutionFeedback(studentReportId, executionId, feedbackToSet)

      setExecutionStatus(updatedExecution.status)
      setExecutionResult(updatedExecution.resultStr)
      setExecutionFeedback(updatedExecution.feedback)
    } catch (e) {
      setExecutionFeedback(initialFeedback)
    }
  }

  function onEditStudentReportClicked () {
    setIsEditStudentReportModalOpen(true)

    setEditedExecutionGrade(executionGrade)
    setEditedExecutionPronouns(executionPronouns)
    setEditedExecutionStrengths(executionStrengths)
    setEditedExecutionOpportunities(executionOpportunities)
  }

  async function onSubmitChanges () {
    if (studentReportId === null || executionId === null || editedExecutionPronouns === null) return

    setIsLoading(true)

    const newAggregatedStudentReportExecution = await studentReportExecutionService.regenerateStudentReportExecution(studentReportId, executionId, editedExecutionGrade, editedExecutionPronouns, editedExecutionStrenghts, editedExecutionOpportunities)

    await setStudentReportAndPollExecution(
      newAggregatedStudentReportExecution.studentReport,
      newAggregatedStudentReportExecution.studentReportCurrentExecution,
      newAggregatedStudentReportExecution.studentReportCurrentExecutionErrorCode,
      newAggregatedStudentReportExecution.studentReportCurrentExecutionLastChatMessageExecution,
      newAggregatedStudentReportExecution.studentReportCurrentExecutionChatMessages
    )

    setIsEditStudentReportModalOpen(false)
    setIsLoading(false)
  }

  async function onSendChatMessage () {
    if (studentReportId === null || executionId === null) return
    setIsLoading(true)
    setChatMessageContent(``)

    const newAggregatedStudentReportExecution = await studentReportExecutionService.sendStudentReportExecutionMessage(studentReportId, executionId, chatMessageContent)

    await setStudentReportAndPollExecution(
      newAggregatedStudentReportExecution.studentReport,
      newAggregatedStudentReportExecution.studentReportCurrentExecution,
      newAggregatedStudentReportExecution.studentReportCurrentExecutionErrorCode,
      newAggregatedStudentReportExecution.studentReportCurrentExecutionLastChatMessageExecution,
      newAggregatedStudentReportExecution.studentReportCurrentExecutionChatMessages
    )

    setIsLoading(false)
  }

  return (
      <>
        <FeatureView
          editor={
            <ReportEditor
              isLoading={isLoading}
              uiStep={uiStep}
              setUIStep={setUIStep}
              onBack={onBack}
              grade={grade}
              setGrade={setGrade}
              pronouns={pronouns}
              setPronouns={setPronouns}
              strengths={strengths}
              setStrengths={setStrengths}
              opportunities={opportunities}
              setOpportunities={setOpportunities}
              onContinueStep={onContinueStep}
              onSkipStep={onSkipStep}
              hasExecuted={hasExecuted}
              onEditStudentReportClicked={onEditStudentReportClicked}
            />}
          executionView={
            <ExecutionView
              isLoading={isLoading}
              hasExecuted={hasExecuted}
              onCopyToClipboard={onCopyToClipboard}
              executionResult={executionResult}
              executionFeedback={executionFeedback}
              onFeedbackGiven={onFeedbackGiven}
              chatMessageContent={chatMessageContent}
              setChatMessageContent={setChatMessageContent}
              onSendChatMessage={onSendChatMessage}
              chatMessageExecutionStatus={chatMessageExecutionStatus}
              chatMessages={chatMessages}
            />}
        />

        <EditStudentReportModal
          isLoading={isLoading}
          isEditStudentReportModalOpen={isEditStudentReportModalOpen}
          setIsEditStudentReportModalOpen={setIsEditStudentReportModalOpen}
          editedExecutionGrade={editedExecutionGrade}
          setEditedExecutionGrade={setEditedExecutionGrade}
          editedExecutionPronouns={editedExecutionPronouns}
          setEditedExecutionPronouns={setEditedExecutionPronouns}
          editedExecutionStrengths={editedExecutionStrenghts}
          setEditedExecutionStrengths={setEditedExecutionStrengths}
          editedExecutionOpportunities={editedExecutionOpportunities}
          setEditedExecutionOpportunties={setEditedExecutionOpportunities}
          grade={grade}
          pronouns={pronouns}
          strengths={strengths}
          opportunities={opportunities}
          onSubmitChanges={onSubmitChanges}
        />

        <ExecutionErrorModal
          executionErrorCode={executionErrorCode}
          setExecutionErrorCode={setExecutionErrorCode}
        />
        <Snackbar
          open={isCopiedToClipboardSnackbarOpen}
          message="Copiado al portapapales"
        />
      </>
  )
}
