import React from 'react'
import { TextField } from '@mui/material'

interface IProps {
  id: string
  error?: string,
  helperText?: string
  placeholder: string
  type?: `email` | `password` | `text` | `number`
  isDisabled: boolean
  value: string
  onChange: (v: string) => void
  onEnter?: () => void
  onShiftEnter?: () => void
  multiline?: boolean
  minRows?: number
  maxRows?: number
}
export default function InTextField (props: IProps) {
  return (
    <TextField
      id={`input-${props.id}`}
      InputProps={{
        sx: { borderRadius: `6px`, border: `1px`, backgroundColor: `white` }
      }}
      error={props.error !== undefined && props.error !== ``}
      helperText={props.error}
      placeholder={props.placeholder}
      fullWidth
      type={props.type !== undefined ? props.type : `text`}
      disabled={props.isDisabled}
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
      onKeyDown={(e) => {
        if (props.onEnter !== undefined) {
          if (e.key !== `Enter`) return
          e.preventDefault()
          props.onEnter()
        }
        if (props.onShiftEnter !== undefined) {
          if (e.key !== `Enter`) return
          if (e.shiftKey) {
            e.preventDefault()
            props.onShiftEnter()
          }
        }
      }}
      multiline={props.multiline !== undefined ? props.multiline : false}
      minRows={props.minRows !== undefined ? props.minRows : 1}
      maxRows={props.maxRows !== undefined ? props.maxRows : 1}
    />
  )
}
