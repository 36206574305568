
import React from "react"

import { Box } from '@mui/material'
import { WORKSHEET_STEP, SCHOOL_GRADE } from "@instruia/utils"

import NewLesson from "./new-lesson"
import ExecutedLesson from "./executed-lesson"
import Header from '../../../components/feature/header'
import PlanearClaseIcon from 'src/assets/home/planear_clase_icon.png'
import { useIsMobile } from "src/modules/utils"

interface IProps {
  isLoading: boolean
  uiStep: WORKSHEET_STEP
  setUIStep: (s: WORKSHEET_STEP) => void
  onBack: () => void
  theme: string
  setTheme: (t: string) => void
  grade: SCHOOL_GRADE | null
  setGrade: (t: SCHOOL_GRADE) => void
  exerciseCount: number
  setExerciseCount: (c: number) => void
  onContinueStep: () => void
  onSkipStep: () => void
  hasExecuted: boolean
  onEditWorksheetClicked: () => void
}

export default function LessonEditor (props: IProps) {
  function getPercentage () {
    if (props.uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1) return 25
    if (props.uiStep === WORKSHEET_STEP.SELECT_GRADE_2) return 50
    if (props.uiStep === WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3) return 50
    if (props.uiStep === WORKSHEET_STEP.DONE_4) return 100
    return 100
  }

  const isMobile = useIsMobile()
  const percentage = getPercentage()
  const isDone = props.uiStep === WORKSHEET_STEP.DONE_4

  return (
    <Box sx={{ flexGrow: 1, display: `flex`, flexDirection: `column`, padding: isMobile ? `0 0 25px 0px` : `25px`, gap: `25px`, maxWidth: `800px` }} >
      <Header
         percentage={percentage}
         isDone={isDone}
         shouldDisplayBackButton={props.uiStep !== WORKSHEET_STEP.DESCRIBE_THEME_1 && props.uiStep !== WORKSHEET_STEP.DONE_4}
         onBack={props.onBack}
         isLoading={props.isLoading}
         icon={PlanearClaseIcon}
         title={`Crear ejercicios`}
         subtitle={`Genera ejercicios para realizar en clase`}
      />
      {props.hasExecuted
        ? (
          <ExecutedLesson
            isLoading={props.isLoading}
            theme={props.theme}
            grade={props.grade}
            exerciseCount={props.exerciseCount}
            onEditLessonPlanClicked={props.onEditWorksheetClicked}
          />
          )
        : (
          <NewLesson
            isLoading={props.isLoading}
            uiStep={props.uiStep}
            setUIStep={props.setUIStep}
            theme={props.theme}
            setTheme={props.setTheme}
            grade={props.grade}
            setGrade={props.setGrade}
            exerciseCount={props.exerciseCount}
            setExerciseCount={props.setExerciseCount}
            onContinueStep={props.onContinueStep}
            onSkipStep={props.onSkipStep}
          />
          )}

    </Box>
  )
}
