import { LinearProgress } from '@mui/material'
import React from 'react'

interface IProps {
  percentage: number
  isDone: boolean
}
export default function ProgressBar (props: IProps) {
  return (
    <LinearProgress
      sx={{ height: `8px`, borderRadius: `100px` }}
      color='secondary'
      variant={props.isDone ? `indeterminate` : `determinate`}
      value={props.percentage}
    />
  )
}
