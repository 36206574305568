import { SCHOOL_GRADE, mapGradeCodeIntoTitle } from '@instruia/utils'
import { Select, FormControl, MenuItem } from '@mui/material'
import React from 'react'

const GRADES = [
  SCHOOL_GRADE.FIRST,
  SCHOOL_GRADE.SECOND,
  SCHOOL_GRADE.THIRD,
  SCHOOL_GRADE.FOURTH,
  SCHOOL_GRADE.FIFTH,
  SCHOOL_GRADE.SIXTH,
  SCHOOL_GRADE.SEVENTH,
  SCHOOL_GRADE.EIGTH,
  SCHOOL_GRADE.NINETH,
  SCHOOL_GRADE.TENTH,
  SCHOOL_GRADE.ELEVENTH
]

interface IProps {
  isDisabled: boolean
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
}
export default function SelectGrade (props: IProps) {
  return (
    <FormControl fullWidth>
      <Select
        sx={{
          backgroundColor: `#FFFFFF`,
          borderRadius: `8px`,
          border: `1px`,
          ".MuiSelect-icon": { color: `primary.main` }
        }}
        disabled={props.isDisabled}
        value={props.grade !== null ? props.grade : `Selecciona un grado`}
        onChange={(e) => {
          const v = e.target.value
          const gradeToSet = GRADES.find(g => g === v) || null
          if (gradeToSet === null) return
          props.setGrade(gradeToSet)
        }}
      >
        {GRADES.map(g => {
          return (
            <MenuItem
              key={g}
              value={g}
            >
              {mapGradeCodeIntoTitle(g)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
