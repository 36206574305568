
export enum ANALYTICS_EVENTS {
  REGISTER_WITH_EMAIL = `REGISTER_WITH_EMAIL`,
  REGISTER_WITH_GOOGLE = `REGISTER_WITH_GOOGLE`,

  LOGIN_WITH_EMAIL =`LOGIN_WITH_EMAIL`,
  LOGIN_WITH_GOOGLE = `LOGIN_WITH_GOOGLE`,

  FEATURE_LESSON_PLAN_START = `FEATURE_LESSON_PLAN_START`,
  FEATURE_LESSON_PLAN_BACK = `FEATURE_LESSON_PLAN_BACK`,
  FEATURE_LESSON_PLAN_SET_SUBJECT = `FEATURE_LESSON_PLAN_SET_SUBJECT`,
  FEATURE_LESSON_PLAN_SKIPPED_SUBJECT = `FEATURE_LESSON_PLAN_SKIPPED_SUBJECT`,
  FEATURE_LESSON_PLAN_SET_THEME = `FEATURE_LESSON_PLAN_SET_THEME`,
  FEATURE_LESSON_PLAN_SET_GRADE = `FEATURE_LESSON_PLAN_SET_GRADE`,
  FEATURE_LESSON_PLAN_SKIPPED_GRADE = `FEATURE_LESSON_PLAN_SKIPPED_GRADE`,
  FEATURE_LESSON_PLAN_SET_ADDITIONAL_DETAILS = `FEATURE_LESSON_PLAN_SET_ADDITIONAL_DETAILS`,
  FEATURE_LESSON_PLAN_SKIPPED_ADDITIONAL_DETAILS = `FEATURE_LESSON_PLAN_SKIPPED_ADDITIONAL_DETAILS`,
  FEATURE_LESSON_PLAN_EXECUTE = `FEATURE_LESSON_PLAN_EXECUTE`,

  FEATURE_STUDENT_REPORT_START = `FEATURE_STUDENT_REPORT_START`,
  FEATURE_STUDENT_REPORT_BACK = `FEATURE_STUDENT_REPORT_BACK`,
  FEATURE_STUDENT_REPORT_SET_GRADE = `FEATURE_STUDENT_REPORT_SET_GRADE`,
  FEATURE_STUDENT_REPORT_SKIPPED_GRADE = `FEATURE_STUDENT_REPORT_SKIPPED_GRADE`,
  FEATURE_STUDENT_REPORT_SET_PRONOUNS = `FEATURE_STUDENT_REPORT_SET_PRONOUNS`,
  FEATURE_STUDENT_REPORT_SKIPPED_PRONOUNS = `FEATURE_STUDENT_REPORT_SKIPPED_PRONOUNS`,
  FEATURE_STUDENT_REPORT_SET_STRENGTHS = `FEATURE_STUDENT_REPORT_SET_STRENGTHS`,
  FEATURE_STUDENT_REPORT_SKIPPED_STRENGTHS = `FEATURE_STUDENT_REPORT_SKIPPED_STRENGTHS`,
  FEATURE_STUDENT_REPORT_SET_OPPORTUNITIES = `FEATURE_STUDENT_REPORT_SET_OPPORTUNITIES`,
  FEATURE_STUDENT_REPORT_SKIPPED_OPPORTUNITIES = `FEATURE_STUDENT_REPORT_SKIPPED_OPPORTUNITIES`,

  FEATURE_WORKSHEET_START = `FEATURE_WORKSHEET_START`,
  FEATURE_WORKSHEET_BACK = `FEATURE_WORKSHEET_BACK`,
  FEATURE_WORKSHEET_SET_THEME = `FEATURE_WORKSHEET_SET_THEME`,
  FEATURE_WORKSHEET_SET_GRADE = `FEATURE_WORKSHEET_SET_GRADE`,
  FEATURE_WORKSHEET_SET_EXERCISE_COUNT = `FEATURE_WORKSHEET_SET_EXERCISE_COUNT`,
  FEATURE_WORKSHEET_SKIPPED_GRADE = `FEATURE_WORKSHEET_SKIPPED_GRADE`,
  FEATURE_WORKSHEET_EXECUTE = `FEATURE_WORKSHEET_EXECUTE`,
}
