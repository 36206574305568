
import { WORKSHEET_STEP, SCHOOL_GRADE } from '@instruia/utils'
import axios from '../../../modules/axios'
import { IWorksheet, IAggregatedWorksheet } from '../types'
import { IWorksheetExecution } from 'src/entities/worksheet/execution/types'

const PATH = `worksheets`

export async function createWorksheet (
  theme: string | null,
  grade: SCHOOL_GRADE | null,
  exerciseCount: number,
  step: WORKSHEET_STEP
): Promise<IWorksheet> {
  const response = await axios({
    method: `post`,
    url: `/${PATH}`,
    data: {
      theme,
      grade,
      exerciseCount,
      step
    }
  })

  return response.data
}

export async function getWorksheet (worksheetId: string): Promise<IAggregatedWorksheet> {
  const response = await axios({
    method: `get`,
    url: `/${PATH}/${worksheetId}`
  })

  return response.data
}

export async function updateWorksheet (
  worksheetId: string,
  theme: string | null,
  grade: SCHOOL_GRADE | null,
  exerciseCount: number | null,
  // if null then do not update step
  step: WORKSHEET_STEP | null,
  shouldExecute: boolean
): Promise<IAggregatedWorksheet> {
  const response = await axios({
    method: `put`,
    url: `/${PATH}/${worksheetId}`,
    data: {
      theme,
      grade,
      step,
      exerciseCount,
      shouldExecute
    }
  })

  return response.data
}

export async function getWorksheetCurrentExecution (worksheetId: string): Promise<IWorksheetExecution> {
  const response = await axios({
    method: `get`,
    url: `/${PATH}/${worksheetId}/executions/current`
  })

  return response.data
}
