import axios from 'axios'

import * as CONFIG from '../../config'
import * as CONSTANTS from '../../config/constants'

const axiosInstance = axios.create({
  baseURL: `${CONFIG.API_URL}/api`
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.JWT)
    if (token === null) return config

    config.headers.Authorization = `Bearer ${token}`
    return config
  }
)

export default axiosInstance
