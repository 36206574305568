import React, { useState, useEffect } from 'react'

import { useTheme } from '@mui/material/styles'
import { Snackbar, useMediaQuery } from '@mui/material'
import { useTeacherState } from 'src/context/teacher'

import { WORKSHEET_STEP, WORKSHEET_EXECUTION_STATUS, ERROR_CODE, WORKSHEET_EXECUTION_FEEDBACK, WORKSHEET_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS, SCHOOL_GRADE, WORKSHEET_EXERCISE_COUNT_DEFAULT } from '@instruia/utils'

import * as worksheetService from 'src/entities/worksheet/service'
import { useNavigate, useParams } from 'react-router-dom'
import { IWorksheet } from 'src/entities/worksheet/types'

import WorksheetEditor from './lesson-editor'
import { IWorksheetExecution } from 'src/entities/worksheet/execution/types'
import ExecutionErrorModal from './execution-error-modal'

import { markdownToTxt } from 'markdown-to-txt'
import { ExecutionView } from './execution-view'

import * as worksheetExecutionService from 'src/entities/worksheet/execution/service'
import EditWorksheetExecutionModal from './edit-lesson-plan-execution-modal'
import { IWorksheetExecutionChatMessage } from 'src/entities/worksheet/execution/chat-message/types'
import { IWorksheetExecutionChatMessageExecution } from 'src/entities/worksheet/execution/chat-message/execution/types'

import * as analytics from '../../modules/analytics'
import { ANALYTICS_EVENTS } from 'src/modules/analytics/analytics-events'

import FeatureView from 'src/components/feature/view'

export default function Worksheet () {
  const params = useParams()
  const navigate = useNavigate()
  const stylesTheme = useTheme()
  const teacherState = useTeacherState()

  // eslint-disable-next-line dot-notation
  const paramsWorksheetId = params[`worksheetId`] || null

  const doesItHaveSidebar = useMediaQuery(stylesTheme.breakpoints.up(`md`))

  const [worksheetId, setWorksheetId] = useState<string | null>(null)
  const [_isLoading, setIsLoading] = useState(false)

  const [uiStep, setUIStep] = useState<WORKSHEET_STEP>(WORKSHEET_STEP.DESCRIBE_THEME_1)
  const [worksheetStep, setWorksheetStep] = useState<WORKSHEET_STEP>(WORKSHEET_STEP.DESCRIBE_THEME_1)

  const [theme, setTheme] = useState(``)
  const [grade, setGrade] = useState<SCHOOL_GRADE | null>(null)
  const [exerciseCount, setExerciseCount] = useState<number>(WORKSHEET_EXERCISE_COUNT_DEFAULT)

  const [executionId, setExecutionId] = useState<string | null>(null)
  const [executionTheme, setExecutionTheme] = useState(``)
  const [executionGrade, setExecutionGrade] = useState<SCHOOL_GRADE | null>(null)
  const [executionExerciseCount, setExecutionExerciseCount] = useState<number>(WORKSHEET_EXERCISE_COUNT_DEFAULT)
  const [executionResult, setExecutionResult] = useState<string | null>(null)
  const [executionStatus, setExecutionStatus] = useState<WORKSHEET_EXECUTION_STATUS | null>(null)
  const [executionFeedback, setExecutionFeedback] = useState<WORKSHEET_EXECUTION_FEEDBACK>(WORKSHEET_EXECUTION_FEEDBACK.NONE)

  const [executionErrorCode, setExecutionErrorCode] = useState<ERROR_CODE.TEACHER__EXECUTE_WORKSHEET__MAX_QUOTA_REACHED | null>(null)
  const [isCopiedToClipboardSnackbarOpen, setIsCopiedToClipboardSnackbarOpen] = useState(false)

  const [isEditWorksheetModalOpen, setIsEditWorksheetModalOpen] = useState(false)

  const [editedExecutionTheme, setEditedExecutionTheme] = useState(``)
  const [editedExecutionGrade, setEditedExecutionGrade] = useState<SCHOOL_GRADE | null>(null)
  const [editedExecutionExerciseCount, setEditedExecutionExerciseCount] = useState<number>(WORKSHEET_EXERCISE_COUNT_DEFAULT)

  const [chatMessageExecutionStatus, setChatMessageExecutionStatus] = useState<WORKSHEET_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS | null>(null)
  const [chatMessages, setChatMessages] = useState<IWorksheetExecutionChatMessage[]>([])
  const [chatMessageContent, setChatMessageContent] = useState(``)

  const drawerWidth = doesItHaveSidebar ? 100 : 0

  const teacher = teacherState.teacher!

  const hasExecuted = executionStatus !== null &&
    [WORKSHEET_EXECUTION_STATUS.COMPLETED, WORKSHEET_EXECUTION_STATUS.FAILED].includes(executionStatus)

  const isLoading = _isLoading || (
    executionStatus !== null &&
    [WORKSHEET_EXECUTION_STATUS.QUEUED, WORKSHEET_EXECUTION_STATUS.RUNNING].includes(executionStatus)
  )

  useEffect(() => {
    fetchWorksheet()
  }, [])

  async function fetchWorksheet () {
    if (paramsWorksheetId === null) return

    setIsLoading(true)

    try {
      const fetchedAggregatedWorksheet = await worksheetService.getWorksheet(paramsWorksheetId)
      await setWorksheetAndLongPollExecution(
        fetchedAggregatedWorksheet.worksheet,
        fetchedAggregatedWorksheet.worksheetCurrentExecution,
        fetchedAggregatedWorksheet.worksheetCurrentExecutionErrorCode,
        fetchedAggregatedWorksheet.worksheetCurrentExecutionLastChatMessageExecution,
        fetchedAggregatedWorksheet.worksheetCurrentExecutionChatMessages
      )

      setIsLoading(false)
    } catch (e: any) {
      if (e.response && e.response.data) {
        const code = e.response.data.code

        if (code === ERROR_CODE.ENTITY_NOT_FOUND) {
          navigate(`/not-found`)
          return
        }
      }
      console.error(`unhandled error`, e)
    }
  }

  async function createOrEditWorksheet () {
    if (theme === null) return

    setIsLoading(true)

    if (worksheetId === null) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_START)

      const newWorksheet = await worksheetService.createWorksheet(theme, grade, exerciseCount, WORKSHEET_STEP.SELECT_GRADE_2)
      navigate(`/worksheets/${newWorksheet._id}`)

      await setWorksheetAndLongPollExecution(newWorksheet, null, null, null, [])

      setIsLoading(false)
      return
    }

    function getStepToUpdate (): WORKSHEET_STEP | null {
      if (uiStep !== worksheetStep) return null

      if (uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1) return WORKSHEET_STEP.SELECT_GRADE_2
      if (uiStep === WORKSHEET_STEP.SELECT_GRADE_2) return WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3
      if (uiStep === WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3) return WORKSHEET_STEP.DONE_4

      return null
    }

    const step = getStepToUpdate()
    const shouldExecute = step === WORKSHEET_STEP.DONE_4

    if (shouldExecute) analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_EXECUTE)

    const updatedAggregatedWorksheet = await worksheetService.updateWorksheet(worksheetId, theme, grade, exerciseCount, step, shouldExecute)

    await setWorksheetAndLongPollExecution(
      updatedAggregatedWorksheet.worksheet,
      updatedAggregatedWorksheet.worksheetCurrentExecution,
      updatedAggregatedWorksheet.worksheetCurrentExecutionErrorCode,
      null,
      []
    )

    setIsLoading(false)
  }

  async function setWorksheetAndLongPollExecution (
    worksheet: IWorksheet,
    currentExecution: IWorksheetExecution | null,
    executionErrorCode: ERROR_CODE.TEACHER__EXECUTE_WORKSHEET__MAX_QUOTA_REACHED | null,
    chatMessageExecution: IWorksheetExecutionChatMessageExecution | null,
    chatMessages: IWorksheetExecutionChatMessage[]
  ) {
    setWorksheetId(worksheet._id)
    setUIStep(worksheet.step)
    setWorksheetStep(worksheet.step)
    setTheme(worksheet.theme ?? ``)
    setGrade(worksheet.grade)
    if (worksheet.exerciseCount !== null) setExerciseCount(worksheet.exerciseCount)

    setExecutionErrorCode(executionErrorCode)

    if (currentExecution === null) {
      setExecutionId(null)
      setExecutionTheme(``)
      setExecutionGrade(null)
      setExecutionExerciseCount(WORKSHEET_EXERCISE_COUNT_DEFAULT)
      setExecutionStatus(null)
      setExecutionResult(null)
      setExecutionFeedback(WORKSHEET_EXECUTION_FEEDBACK.NONE)

      setEditedExecutionTheme(``)
      setEditedExecutionGrade(null)

      setChatMessageExecutionStatus(null)
      setChatMessages([])
      return
    }

    setExecutionId(currentExecution._id)
    setExecutionTheme(currentExecution.theme ?? ``)
    setExecutionGrade(currentExecution.grade)
    setExecutionStatus(currentExecution.status)
    setExecutionResult(currentExecution.resultStr)
    setExecutionFeedback(currentExecution.feedback)

    setEditedExecutionTheme(currentExecution.theme ?? ``)
    setEditedExecutionGrade(currentExecution.grade)

    let shouldContinue1 = ![WORKSHEET_EXECUTION_STATUS.FAILED, WORKSHEET_EXECUTION_STATUS.COMPLETED].includes(currentExecution.status)
    while (shouldContinue1) {
      await new Promise(resolve => setTimeout(resolve, 2000))

      const exec = await worksheetService.getWorksheetCurrentExecution(worksheet._id)

      if ([WORKSHEET_EXECUTION_STATUS.FAILED, WORKSHEET_EXECUTION_STATUS.COMPLETED].includes(exec.status)) {
        shouldContinue1 = false

        setExecutionTheme(exec.theme ?? ``)
        setExecutionGrade(exec.grade)
        setExecutionStatus(exec.status)
        setExecutionResult(exec.resultStr)
        setExecutionFeedback(exec.feedback)

        setEditedExecutionTheme(exec.theme ?? ``)
        setEditedExecutionGrade(exec.grade)
      }
    }

    setChatMessages(chatMessages)
    if (chatMessageExecution === null) setChatMessageExecutionStatus(null)
    else {
      setChatMessageExecutionStatus(chatMessageExecution.status)

      let shouldContinue2 = ![WORKSHEET_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.FAILED, WORKSHEET_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.COMPLETED].includes(chatMessageExecution.status)
      while (shouldContinue2) {
        await new Promise(resolve => setTimeout(resolve, 2000))

        const exec = await worksheetExecutionService.getWorksheetCurrentExecutionLastChatMessageExecution(worksheet._id, currentExecution._id)

        if ([WORKSHEET_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.FAILED, WORKSHEET_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.COMPLETED].includes(exec.status)) {
          shouldContinue2 = false
          setChatMessageExecutionStatus(exec.status)
        }
      }

      const newChatMessages = await worksheetExecutionService.getWorksheetCurrentExecutionChatMessages(worksheet._id, currentExecution._id)
      setChatMessages(newChatMessages)
    }
  }

  async function onContinueStep () {
    await createOrEditWorksheet()

    if (uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_SET_THEME)
      setUIStep(WORKSHEET_STEP.SELECT_GRADE_2)
      return
    }

    if (uiStep === WORKSHEET_STEP.SELECT_GRADE_2) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_SET_GRADE)
      setUIStep(WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3)
      return
    }

    if (uiStep === WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_SET_GRADE)
      setUIStep(WORKSHEET_STEP.DONE_4)
    }
  }

  async function onSkipStep () {
    if (uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1) return

    if (uiStep === WORKSHEET_STEP.SELECT_GRADE_2) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_SKIPPED_GRADE)
      setUIStep(WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3)
    }
  }

  function onBack () {
    analytics.track(ANALYTICS_EVENTS.FEATURE_WORKSHEET_BACK)

    if (uiStep === WORKSHEET_STEP.SELECT_GRADE_2) {
      setUIStep(WORKSHEET_STEP.DESCRIBE_THEME_1)
      return
    }

    if (uiStep === WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3) {
      setUIStep(WORKSHEET_STEP.SELECT_GRADE_2)
    }
  }

  async function onCopyToClipboard () {
    if (executionResult === null) return
    const text = markdownToTxt(executionResult)
    navigator.clipboard.writeText(text)
    setIsCopiedToClipboardSnackbarOpen(true)

    setTimeout(() => setIsCopiedToClipboardSnackbarOpen(false), 2000)
  }

  async function onFeedbackGiven (feedback: WORKSHEET_EXECUTION_FEEDBACK) {
    if (worksheetId === null || executionId === null) return

    const initialFeedback = executionFeedback
    const feedbackToSet = feedback === executionFeedback ? WORKSHEET_EXECUTION_FEEDBACK.NONE : feedback
    setExecutionFeedback(feedbackToSet)

    try {
      const updatedExecution = await worksheetExecutionService.setWorksheetExecutionFeedback(worksheetId, executionId, feedbackToSet)

      setExecutionStatus(updatedExecution.status)
      setExecutionResult(updatedExecution.resultStr)
      setExecutionFeedback(updatedExecution.feedback)
    } catch (e) {
      setExecutionFeedback(initialFeedback)
    }
  }

  function onEditWorksheetClicked () {
    setIsEditWorksheetModalOpen(true)

    setEditedExecutionTheme(executionTheme)
    setEditedExecutionGrade(executionGrade)
  }

  async function onSubmitChanges () {
    if (worksheetId === null || executionId === null || editedExecutionTheme === null) return

    setIsLoading(true)

    const newAggregatedWorksheetExecution = await worksheetExecutionService.regenerateWorksheetExecution(worksheetId, executionId, editedExecutionTheme, editedExecutionGrade, editedExecutionExerciseCount)

    await setWorksheetAndLongPollExecution(
      newAggregatedWorksheetExecution.worksheet,
      newAggregatedWorksheetExecution.worksheetCurrentExecution,
      newAggregatedWorksheetExecution.worksheetCurrentExecutionErrorCode,
      newAggregatedWorksheetExecution.worksheetCurrentExecutionLastChatMessageExecution,
      newAggregatedWorksheetExecution.worksheetCurrentExecutionChatMessages
    )

    setIsEditWorksheetModalOpen(false)
    setIsLoading(false)
  }

  async function onSendChatMessage () {
    if (worksheetId === null || executionId === null) return
    setIsLoading(true)
    setChatMessageContent(``)

    const newAggregatedWorksheetExecution = await worksheetExecutionService.sendWorksheetExecutionMessage(worksheetId, executionId, chatMessageContent)

    await setWorksheetAndLongPollExecution(
      newAggregatedWorksheetExecution.worksheet,
      newAggregatedWorksheetExecution.worksheetCurrentExecution,
      newAggregatedWorksheetExecution.worksheetCurrentExecutionErrorCode,
      newAggregatedWorksheetExecution.worksheetCurrentExecutionLastChatMessageExecution,
      newAggregatedWorksheetExecution.worksheetCurrentExecutionChatMessages
    )

    setIsLoading(false)
  }

  return (
    <>
      <FeatureView
        editor={
          <WorksheetEditor
            isLoading={isLoading}
            uiStep={uiStep}
            setUIStep={setUIStep}
            onBack={onBack}
            theme={theme}
            setTheme={setTheme}
            grade={grade}
            setGrade={setGrade}
            exerciseCount={exerciseCount}
            setExerciseCount={setExerciseCount}
            onContinueStep={onContinueStep}
            onSkipStep={onSkipStep}
            hasExecuted={hasExecuted}
            onEditWorksheetClicked={onEditWorksheetClicked}
          />}
        executionView={
          <ExecutionView
            isLoading={isLoading}
            hasExecuted={hasExecuted}
            onCopyToClipboard={onCopyToClipboard}
            executionResult={executionResult}
            executionFeedback={executionFeedback}
            onFeedbackGiven={onFeedbackGiven}
            chatMessageContent={chatMessageContent}
            setChatMessageContent={setChatMessageContent}
            onSendChatMessage={onSendChatMessage}
            chatMessageExecutionStatus={chatMessageExecutionStatus}
            chatMessages={chatMessages}
          />}
      />
      <EditWorksheetExecutionModal
        isLoading={isLoading}
        isEditWorksheetModalOpen={isEditWorksheetModalOpen}
        setIsEditWorksheetModalOpen={setIsEditWorksheetModalOpen}
        editedExecutionTheme={editedExecutionTheme}
        setEditedExecutionTheme={setEditedExecutionTheme}
        editedExecutionGrade={editedExecutionGrade}
        setEditedExecutionGrade={setEditedExecutionGrade}
        editedExecutionExerciseCount={editedExecutionExerciseCount}
        setEditedExecutionExerciseCount={setEditedExecutionExerciseCount}
        theme={theme}
        grade={grade}
        exerciseCount={exerciseCount}
        setExerciseCount={setExerciseCount}
        onSubmitChanges={onSubmitChanges}
      />
      <ExecutionErrorModal
        executionErrorCode={executionErrorCode}
        setExecutionErrorCode={setExecutionErrorCode}
      />
      <Snackbar
        open={isCopiedToClipboardSnackbarOpen}
        message="Copiado al portapapales"
      />
    </>
  )
}
