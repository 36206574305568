import React, { useState, useEffect } from 'react'

import { useTheme } from '@mui/material/styles'
import { Snackbar, useMediaQuery } from '@mui/material'
import { useTeacherState } from 'src/context/teacher'

import { SCHOOL_SUBJECT, LESSON_PLAN_STEP, LESSON_PLAN_EXECUTION_STATUS, ERROR_CODE, LESSON_PLAN_EXECUTION_FEEDBACK, LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS, SCHOOL_GRADE } from '@instruia/utils'

import * as lessonPlanService from 'src/entities/lesson-plan/service'
import { useNavigate, useParams } from 'react-router-dom'
import { ILessonPlan } from 'src/entities/lesson-plan/types'

import LessonEditor from './lesson-editor'
import { ILessonPlanExecution } from 'src/entities/lesson-plan/execution/types'
import ExecutionErrorModal from './execution-error-modal'

import { markdownToTxt } from 'markdown-to-txt'
import { ExecutionView } from './execution-view'

import * as lessonPlanExecutionService from 'src/entities/lesson-plan/execution/service'
import EditLessonPlanExecutionModal from './edit-lesson-plan-execution-modal'
import { ILessonPlanExecutionChatMessage } from 'src/entities/lesson-plan/execution/chat-message/types'
import { ILessonPlanExecutionChatMessageExecution } from 'src/entities/lesson-plan/execution/chat-message/execution/types'

import * as analytics from '../../modules/analytics'
import { ANALYTICS_EVENTS } from 'src/modules/analytics/analytics-events'

import FeatureView from 'src/components/feature/view'

export default function LessonPlan () {
  const params = useParams()
  const navigate = useNavigate()
  const stylesTheme = useTheme()
  const teacherState = useTeacherState()

  // eslint-disable-next-line dot-notation
  const paramsLessonPlanId = params[`lessonPlanId`] || null

  const doesItHaveSidebar = useMediaQuery(stylesTheme.breakpoints.up(`md`))

  const [lessonPlanId, setLessonPlanId] = useState<string | null>(null)
  const [_isLoading, setIsLoading] = useState(false)

  const [uiStep, setUIStep] = useState<LESSON_PLAN_STEP>(LESSON_PLAN_STEP.SELECT_SUBJECT_1)
  const [lessonPlanStep, setLessonPlanStep] = useState<LESSON_PLAN_STEP>(LESSON_PLAN_STEP.SELECT_SUBJECT_1)

  const [subject, setSubject] = useState<SCHOOL_SUBJECT | null>(null)
  const [theme, setTheme] = useState(``)
  const [grade, setGrade] = useState<SCHOOL_GRADE | null>(null)
  const [additionalDetails, setAdditionalDetails] = useState(``)

  const [executionId, setExecutionId] = useState<string | null>(null)
  const [executionSubject, setExecutionSubject] = useState<SCHOOL_SUBJECT | null>(null)
  const [executionTheme, setExecutionTheme] = useState(``)
  const [executionGrade, setExecutionGrade] = useState<SCHOOL_GRADE | null>(null)
  const [executionAdditionalDetails, setExecutionAdditionalDetails] = useState(``)
  const [executionResult, setExecutionResult] = useState<string | null>(null)
  const [executionStatus, setExecutionStatus] = useState<LESSON_PLAN_EXECUTION_STATUS | null>(null)
  const [executionFeedback, setExecutionFeedback] = useState<LESSON_PLAN_EXECUTION_FEEDBACK>(LESSON_PLAN_EXECUTION_FEEDBACK.NONE)

  const [executionErrorCode, setExecutionErrorCode] = useState<ERROR_CODE.TEACHER__EXECUTE_LESSON_PLAN__MAX_QUOTA_REACHED | null>(null)
  const [isCopiedToClipboardSnackbarOpen, setIsCopiedToClipboardSnackbarOpen] = useState(false)

  const [isEditLessonPlanModalOpen, setIsEditLessonPlanModalOpen] = useState(false)

  const [editedExecutionSubject, setEditedExecutionSubject] = useState<SCHOOL_SUBJECT | null>(null)
  const [editedExecutionTheme, setEditedExecutionTheme] = useState(``)
  const [editedExecutionGrade, setEditedExecutionGrade] = useState<SCHOOL_GRADE | null>(null)
  const [editedExecutionAdditionalDetails, setEditedExecutionAdditionalDetails] = useState(``)

  const [chatMessageExecutionStatus, setChatMessageExecutionStatus] = useState<LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS | null>(null)
  const [chatMessages, setChatMessages] = useState<ILessonPlanExecutionChatMessage[]>([])
  const [chatMessageContent, setChatMessageContent] = useState(``)

  const drawerWidth = doesItHaveSidebar ? 100 : 0

  const teacher = teacherState.teacher!

  const hasExecuted = executionStatus !== null &&
    [LESSON_PLAN_EXECUTION_STATUS.COMPLETED, LESSON_PLAN_EXECUTION_STATUS.FAILED].includes(executionStatus)

  const isLoading = _isLoading || (
    executionStatus !== null &&
    [LESSON_PLAN_EXECUTION_STATUS.QUEUED, LESSON_PLAN_EXECUTION_STATUS.RUNNING].includes(executionStatus)
  )

  useEffect(() => {
    fetchLessonPlan()
  }, [])

  async function fetchLessonPlan () {
    if (paramsLessonPlanId === null) return

    setIsLoading(true)

    try {
      const fetchedAggregatedLessonPlan = await lessonPlanService.getLessonPlan(paramsLessonPlanId)
      await setLessonPlanAndLongPollExecution(
        fetchedAggregatedLessonPlan.lessonPlan,
        fetchedAggregatedLessonPlan.lessonPlanCurrentExecution,
        fetchedAggregatedLessonPlan.lessonPlanCurrentExecutionErrorCode,
        fetchedAggregatedLessonPlan.lessonPlanCurrentExecutionLastChatMessageExecution,
        fetchedAggregatedLessonPlan.lessonPlanCurrentExecutionChatMessages
      )

      setIsLoading(false)
    } catch (e: any) {
      if (e.response && e.response.data) {
        const code = e.response.data.code

        if (code === ERROR_CODE.ENTITY_NOT_FOUND) {
          navigate(`/not-found`)
          return
        }
      }
      console.error(`unhandled error`, e)
    }
  }

  async function createOrEditLessonPlan () {
    if (subject === null) return

    setIsLoading(true)

    if (lessonPlanId === null) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_START)

      const newLessonPlan = await lessonPlanService.createLessonPlan(subject, theme, grade, additionalDetails, LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2)
      navigate(`/lesson-plans/${newLessonPlan._id}`)

      await setLessonPlanAndLongPollExecution(newLessonPlan, null, null, null, [])

      setIsLoading(false)
      return
    }

    function getStepToUpdate (): LESSON_PLAN_STEP | null {
      if (uiStep !== lessonPlanStep) return null

      if (uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1) return LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2
      if (uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2) return LESSON_PLAN_STEP.SELECT_GRADE_3
      if (uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3) return LESSON_PLAN_STEP.MORE_DETAILS_4
      if (uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4) return LESSON_PLAN_STEP.DONE_5

      return null
    }

    const step = getStepToUpdate()
    const shouldExecute = step === LESSON_PLAN_STEP.DONE_5

    if (shouldExecute) analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_EXECUTE)

    const updatedAggregatedLessonPlan = await lessonPlanService.updateLessonPlan(lessonPlanId, subject, theme, grade, additionalDetails, step, shouldExecute)

    await setLessonPlanAndLongPollExecution(
      updatedAggregatedLessonPlan.lessonPlan,
      updatedAggregatedLessonPlan.lessonPlanCurrentExecution,
      updatedAggregatedLessonPlan.lessonPlanCurrentExecutionErrorCode,
      null,
      []
    )

    setIsLoading(false)
  }

  async function setLessonPlanAndLongPollExecution (
    lessonPlan: ILessonPlan,
    currentExecution: ILessonPlanExecution | null,
    executionErrorCode: ERROR_CODE.TEACHER__EXECUTE_LESSON_PLAN__MAX_QUOTA_REACHED | null,
    chatMessageExecution: ILessonPlanExecutionChatMessageExecution | null,
    chatMessages: ILessonPlanExecutionChatMessage[]
  ) {
    setLessonPlanId(lessonPlan._id)
    setUIStep(lessonPlan.step)
    setLessonPlanStep(lessonPlan.step)
    setSubject(lessonPlan.subject)
    setTheme(lessonPlan.theme ?? ``)
    setGrade(lessonPlan.grade)
    setAdditionalDetails(lessonPlan.additionalDetails ?? ``)

    setExecutionErrorCode(executionErrorCode)

    if (currentExecution === null) {
      setExecutionId(null)
      setExecutionSubject(null)
      setExecutionTheme(``)
      setExecutionGrade(null)
      setExecutionAdditionalDetails(``)
      setExecutionStatus(null)
      setExecutionResult(null)
      setExecutionFeedback(LESSON_PLAN_EXECUTION_FEEDBACK.NONE)

      setEditedExecutionSubject(null)
      setEditedExecutionTheme(``)
      setEditedExecutionGrade(null)
      setEditedExecutionAdditionalDetails(``)

      setChatMessageExecutionStatus(null)
      setChatMessages([])
      return
    }

    setExecutionId(currentExecution._id)
    setExecutionSubject(currentExecution.subject)
    setExecutionTheme(currentExecution.theme ?? ``)
    setExecutionGrade(currentExecution.grade)
    setExecutionAdditionalDetails(currentExecution.additionalDetails ?? ``)
    setExecutionStatus(currentExecution.status)
    setExecutionResult(currentExecution.resultStr)
    setExecutionFeedback(currentExecution.feedback)

    setEditedExecutionSubject(currentExecution.subject)
    setEditedExecutionTheme(currentExecution.theme ?? ``)
    setEditedExecutionGrade(currentExecution.grade)
    setEditedExecutionAdditionalDetails(currentExecution.additionalDetails ?? ``)

    let shouldContinue1 = ![LESSON_PLAN_EXECUTION_STATUS.FAILED, LESSON_PLAN_EXECUTION_STATUS.COMPLETED].includes(currentExecution.status)
    while (shouldContinue1) {
      await new Promise(resolve => setTimeout(resolve, 2000))

      const exec = await lessonPlanService.getLessonPlanCurrentExecution(lessonPlan._id)

      if ([LESSON_PLAN_EXECUTION_STATUS.FAILED, LESSON_PLAN_EXECUTION_STATUS.COMPLETED].includes(exec.status)) {
        shouldContinue1 = false

        setExecutionSubject(exec.subject)
        setExecutionTheme(exec.theme ?? ``)
        setExecutionGrade(exec.grade)
        setExecutionAdditionalDetails(exec.additionalDetails ?? ``)
        setExecutionStatus(exec.status)
        setExecutionResult(exec.resultStr)
        setExecutionFeedback(exec.feedback)

        setEditedExecutionSubject(exec.subject)
        setEditedExecutionTheme(exec.theme ?? ``)
        setEditedExecutionGrade(exec.grade)
        setEditedExecutionAdditionalDetails(exec.additionalDetails ?? ``)
      }
    }

    setChatMessages(chatMessages)
    if (chatMessageExecution === null) setChatMessageExecutionStatus(null)
    else {
      setChatMessageExecutionStatus(chatMessageExecution.status)

      let shouldContinue2 = ![LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.FAILED, LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.COMPLETED].includes(chatMessageExecution.status)
      while (shouldContinue2) {
        await new Promise(resolve => setTimeout(resolve, 2000))

        const exec = await lessonPlanExecutionService.getLessonPlanCurrentExecutionLastChatMessageExecution(lessonPlan._id, currentExecution._id)

        if ([LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.FAILED, LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.COMPLETED].includes(exec.status)) {
          shouldContinue2 = false
          setChatMessageExecutionStatus(exec.status)
        }
      }

      const newChatMessages = await lessonPlanExecutionService.getLessonPlanCurrentExecutionChatMessages(lessonPlan._id, currentExecution._id)
      setChatMessages(newChatMessages)
    }
  }

  async function onContinueStep () {
    await createOrEditLessonPlan()

    if (uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_SET_SUBJECT)
      setUIStep(LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2)
      return
    }

    if (uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_SET_THEME)
      setUIStep(LESSON_PLAN_STEP.SELECT_GRADE_3)
      return
    }

    if (uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_SET_GRADE)
      setUIStep(LESSON_PLAN_STEP.MORE_DETAILS_4)
    }
  }

  async function onSkipStep () {
    if (uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_SKIPPED_SUBJECT)
      setUIStep(LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2)
      setSubject(null)
      return
    }

    if (uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2) {
      setTheme(``)
      return
    }

    if (uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_SKIPPED_GRADE)
      setUIStep(LESSON_PLAN_STEP.MORE_DETAILS_4)
      setGrade(null)
      return
    }

    if (uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4) {
      analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_SKIPPED_ADDITIONAL_DETAILS)
      setAdditionalDetails(``)
      await createOrEditLessonPlan()
    }
  }

  function onBack () {
    analytics.track(ANALYTICS_EVENTS.FEATURE_LESSON_PLAN_BACK)

    if (uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2) {
      setUIStep(LESSON_PLAN_STEP.SELECT_SUBJECT_1)
      return
    }
    if (uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3) {
      setUIStep(LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2)
      return
    }
    if (uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4) {
      setUIStep(LESSON_PLAN_STEP.SELECT_GRADE_3)
    }
  }

  async function onCopyToClipboard () {
    if (executionResult === null) return
    const text = markdownToTxt(executionResult)
    navigator.clipboard.writeText(text)
    setIsCopiedToClipboardSnackbarOpen(true)

    setTimeout(() => setIsCopiedToClipboardSnackbarOpen(false), 2000)
  }

  async function onFeedbackGiven (feedback: LESSON_PLAN_EXECUTION_FEEDBACK) {
    if (lessonPlanId === null || executionId === null) return

    const initialFeedback = executionFeedback
    const feedbackToSet = feedback === executionFeedback ? LESSON_PLAN_EXECUTION_FEEDBACK.NONE : feedback
    setExecutionFeedback(feedbackToSet)

    try {
      const updatedExecution = await lessonPlanExecutionService.setLessonPlanExecutionFeedback(lessonPlanId, executionId, feedbackToSet)

      setExecutionStatus(updatedExecution.status)
      setExecutionResult(updatedExecution.resultStr)
      setExecutionFeedback(updatedExecution.feedback)
    } catch (e) {
      setExecutionFeedback(initialFeedback)
    }
  }

  function onEditLessonPlanClicked () {
    setIsEditLessonPlanModalOpen(true)

    setEditedExecutionSubject(executionSubject)
    setEditedExecutionTheme(executionTheme)
    setEditedExecutionGrade(executionGrade)
    setEditedExecutionAdditionalDetails(executionAdditionalDetails)
  }

  async function onSubmitChanges () {
    if (lessonPlanId === null || executionId === null || editedExecutionSubject === null) return

    setIsLoading(true)

    const newAggregatedLessonPlanExecution = await lessonPlanExecutionService.regenerateLessonPlanExecution(lessonPlanId, executionId, editedExecutionSubject, editedExecutionTheme, editedExecutionGrade, editedExecutionAdditionalDetails)

    await setLessonPlanAndLongPollExecution(
      newAggregatedLessonPlanExecution.lessonPlan,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecution,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecutionErrorCode,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecutionLastChatMessageExecution,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecutionChatMessages
    )

    setIsEditLessonPlanModalOpen(false)
    setIsLoading(false)
  }

  async function onSendChatMessage () {
    if (lessonPlanId === null || executionId === null) return
    setIsLoading(true)
    setChatMessageContent(``)

    const newAggregatedLessonPlanExecution = await lessonPlanExecutionService.sendLessonPlanExecutionMessage(lessonPlanId, executionId, chatMessageContent)

    await setLessonPlanAndLongPollExecution(
      newAggregatedLessonPlanExecution.lessonPlan,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecution,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecutionErrorCode,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecutionLastChatMessageExecution,
      newAggregatedLessonPlanExecution.lessonPlanCurrentExecutionChatMessages
    )

    setIsLoading(false)
  }

  return (
    <>
      <FeatureView
        editor={
          <LessonEditor
            isLoading={isLoading}
            uiStep={uiStep}
            setUIStep={setUIStep}
            onBack={onBack}
            subject={subject}
            setSubject={setSubject}
            theme={theme}
            setTheme={setTheme}
            grade={grade}
            setGrade={setGrade}
            additionalDetails={additionalDetails}
            setAdditionalDetails={setAdditionalDetails}
            onContinueStep={onContinueStep}
            onSkipStep={onSkipStep}
            hasExecuted={hasExecuted}
            onEditLessonPlanClicked={onEditLessonPlanClicked}
          />}
        executionView={
          <ExecutionView
            isLoading={isLoading}
            hasExecuted={hasExecuted}
            onCopyToClipboard={onCopyToClipboard}
            executionResult={executionResult}
            executionFeedback={executionFeedback}
            onFeedbackGiven={onFeedbackGiven}
            chatMessageContent={chatMessageContent}
            setChatMessageContent={setChatMessageContent}
            onSendChatMessage={onSendChatMessage}
            chatMessageExecutionStatus={chatMessageExecutionStatus}
            chatMessages={chatMessages}
          />}
      />
      <EditLessonPlanExecutionModal
        isLoading={isLoading}
        isEditLessonPlanModalOpen={isEditLessonPlanModalOpen}
        setIsEditLessonPlanModalOpen={setIsEditLessonPlanModalOpen}
        editedExecutionSubject={editedExecutionSubject}
        setEditedExecutionSubject={setEditedExecutionSubject}
        editedExecutionTheme={editedExecutionTheme}
        setEditedExecutionTheme={setEditedExecutionTheme}
        editedExecutionGrade={editedExecutionGrade}
        setEditedExecutionGrade={setEditedExecutionGrade}
        editedExecutionAdditionalDetails={editedExecutionAdditionalDetails}
        setEditedExecutionAdditionalDetails={setEditedExecutionAdditionalDetails}
        subject={subject}
        theme={theme}
        grade={grade}
        additionalDetails={additionalDetails}
        onSubmitChanges={onSubmitChanges}
      />

      <ExecutionErrorModal
        executionErrorCode={executionErrorCode}
        setExecutionErrorCode={setExecutionErrorCode}
      />
      <Snackbar
        open={isCopiedToClipboardSnackbarOpen}
        message="Copiado al portapapales"
      />
    </>
  )
}
