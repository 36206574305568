
import { IEnterResponse } from 'src/entities/teacher/types'
import * as CONFIG from '../../config'
import mixpanel from 'mixpanel-browser'
import { ANALYTICS_EVENTS } from './analytics-events'

const MIXPANEL_PROJECT_TOKEN = CONFIG.MIXPANEL_PROJECT_TOKEN

export async function init () {
  if (MIXPANEL_PROJECT_TOKEN === null) return
  mixpanel.init(MIXPANEL_PROJECT_TOKEN, { debug: true, track_pageview: `url-with-path-and-query-string`, persistence: `localStorage` })
}

export async function identifyTeacher (enterResponse: IEnterResponse) {
  if (MIXPANEL_PROJECT_TOKEN === null) return
  mixpanel.identify(enterResponse.teacher._id)
  mixpanel.people.set({ tier: enterResponse.teacher.tier })
}

export function track (event: ANALYTICS_EVENTS) {
  if (MIXPANEL_PROJECT_TOKEN === null) return
  mixpanel.track(event)
}
