import React from 'react'

import { SCHOOL_GRADE } from '@instruia/utils'

import EditExecutionModal from 'src/components/feature/edit-execution-modal'

import SelectGradeStep1 from '../report-editor/steps/1-select-grade'
import SetPronounsStep2 from '../report-editor/steps/2-set-pronouns'
import SetStrengthsStep3 from '../report-editor/steps/3-set-strengths'
import SetOpportunitiesStep4 from '../report-editor/steps/4-set-opportunities'

interface IProps {
  isLoading: boolean
  isEditStudentReportModalOpen: boolean
  setIsEditStudentReportModalOpen: (c: boolean) => void
  editedExecutionGrade: SCHOOL_GRADE | null
  setEditedExecutionGrade: (g: SCHOOL_GRADE) => void
  editedExecutionPronouns: string
  setEditedExecutionPronouns: (s: string) => void
  editedExecutionStrengths: string
  setEditedExecutionStrengths: (t: string) => void
  editedExecutionOpportunities: string
  setEditedExecutionOpportunties: (d: string) => void
  grade: SCHOOL_GRADE | null
  pronouns: string
  strengths: string
  opportunities: string
  onSubmitChanges: () => void
}

export default function EditStudentReportModal (props: IProps) {
  function getIsButtonDisabled (): boolean {
    if (props.isLoading) return true

    if (props.editedExecutionGrade !== props.grade) return false
    if (props.editedExecutionPronouns !== props.pronouns) return false
    if (props.editedExecutionStrengths !== props.strengths) return false
    if (props.editedExecutionOpportunities !== props.opportunities) return false
    return true
  }

  const isButtonDisabled = getIsButtonDisabled()

  return (
    <EditExecutionModal
      title="Reporte del estudiante"
      isButtonDisabled={isButtonDisabled}
      isEditExecutionModalOpen={props.isEditStudentReportModalOpen}
      setIsEditExecutionModalOpen={props.setIsEditStudentReportModalOpen}
      onSubmitChanges={props.onSubmitChanges}
    >
      <SelectGradeStep1
        isDisabled={props.isLoading}
        grade={props.editedExecutionGrade}
        setGrade={props.setEditedExecutionGrade}
      />
      <SetPronounsStep2
        isLoading={props.isLoading}
        pronouns={props.editedExecutionPronouns}
        setPronouns={props.setEditedExecutionPronouns}
      />
      <SetStrengthsStep3
        isLoading={props.isLoading}
        strengths={props.editedExecutionStrengths}
        setStrengths={props.setEditedExecutionStrengths}
      />
      <SetOpportunitiesStep4
        isLoading={props.isLoading}
        opportunities={props.editedExecutionOpportunities}
        setOpportunities={props.setEditedExecutionOpportunties}
      />
    </EditExecutionModal>
  )
}
