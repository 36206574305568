import React from 'react'

import TextField from 'src/components/text-field'

interface IProps {
  isLoading: boolean
  theme: string
  setTheme: (s: string) => void
  onContinueStep?: () => void
}
export default function DescribeThemeStep1 (props: IProps) {
  return (
    <TextField
      placeholder="El tema de tu clase, como por ejemplo Movimiento Uniformente Acelerado, o Coordenadas Polares"
      isDisabled={props.isLoading}
      value={props.theme}
      onChange={props.setTheme}
      multiline
      minRows={4}
      maxRows={10}
      onShiftEnter={() => {
        if (props.onContinueStep === undefined) return

        if (props.theme === ``) return
        props.onContinueStep()
      }}
    />
  )
}
