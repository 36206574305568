import React from 'react'
import { Box } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

interface IProps {
  shouldDisplayBackButton: boolean
  onBack: () => void
}

export default function Back (props: IProps) {
  return (
    <Box sx={{ minHeight: `24px` }} >
      {props.shouldDisplayBackButton && (
        <Box
          sx={{ display: `flex`, alignItems: `center`, cursor: `pointer`, minHeight: `24px` }}
          onClick={props.onBack}
        >
          <ChevronLeft />
          Atrás
        </Box>
      )}
    </Box>
  )
}
