import { Button, Typography, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Logo from '../../components/logo'
import TextField from '../../components/text-field'

import * as teacherService from '../../entities/teacher/service'

import { ERROR_CODE } from '@instruia/utils'

const ERROR_CODE_TRY_AGAIN = `ERROR_CODE_TRY_AGAIN`

function ResetPassword () {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const paramsCode = searchParams.get(`code`)

  const [isLoading, setIsLoading] = useState(true)
  const [errorCode, setErrorCode] = useState<ERROR_CODE | null | `ERROR_CODE_TRY_AGAIN`>(null)

  const [hasSuccedeed, setHasSuccedeed] = useState(false)

  const [newPassword, setNewPassword] = useState(``)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState(``)

  const [newPasswordError, setNewPasswordError] = useState(``)
  const newPasswordConfirmationError = newPasswordConfirmation === `` ? `` : newPassword === newPasswordConfirmation ? `` : `Contraseñas no son iguales`

  useEffect(() => {
    // http://localhost:5056/reset-password?code=omPCnh16NAdcvqPzJJBn
    if (paramsCode === null || paramsCode === ``) {
      navigate(`/login`, { replace: true })
    }
    setIsLoading(false)
  }, [])

  async function resetPassword () {
    if (paramsCode === null) return
    if (errorCode !== null) setErrorCode(null)
    if (newPasswordError !== null) setNewPasswordError(``)

    try {
      setIsLoading(true)

      await teacherService.resetPassword(paramsCode, newPassword)
      setHasSuccedeed(true)
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)

      if (e.response.data !== undefined && e.response.data.code !== undefined) {
        const code = e.response.data.code
        if (code === ERROR_CODE.TEACHER__RESET_PASSWORD__INEXISTING_CODE) setErrorCode(ERROR_CODE.TEACHER__RESET_PASSWORD__INEXISTING_CODE)
        else if (code === ERROR_CODE.TEACHER__RESET_PASSWORD__CODE_EXPIRED) setErrorCode(ERROR_CODE.TEACHER__RESET_PASSWORD__CODE_EXPIRED)
        else if (code === ERROR_CODE.TEACHER__RESET_PASSWORD_CODE_ALREADY_USED) setErrorCode(ERROR_CODE.TEACHER__RESET_PASSWORD_CODE_ALREADY_USED)
        else setErrorCode(ERROR_CODE_TRY_AGAIN)
        return
      }
      setErrorCode(ERROR_CODE_TRY_AGAIN)
    }
  }

  function getErrorMessage () {
    if (errorCode === ERROR_CODE.TEACHER__RESET_PASSWORD__INEXISTING_CODE) {
      return `Link inválido`
    }
    if (errorCode === ERROR_CODE.TEACHER__RESET_PASSWORD__CODE_EXPIRED) {
      return `El código expiró, inicia el proceso de nuevo`
    }
    if (errorCode === ERROR_CODE.TEACHER__RESET_PASSWORD_CODE_ALREADY_USED) {
      return `El código ya fue utilizado`
    }
    return `Error desconocido, intenta más tarde`
  }

  if (isLoading) {
    return (
      <Box sx={{ marginTop: `30px`, padding: `0px 0px 0px 0px` }}>
        <Typography fontSize="22px">
          Cargando
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Logo />
        <Box sx={{
          display: `flex`,
          flexFlow: `row wrap`,
          justifyContent: `center`,
          alignItems: `center`,
          width: `100%`,
          minHeight: `calc(100vh - 45px - 25px - 100px)`
        }}>

          <Box sx={{ padding: `50px 20px 50px 20px`, maxWidth: `480px`, flexGrow: 1 }}>

            <Box sx={{ marginBottom: `40px` }}>
              <Typography fontSize="36px" fontWeight="700" textAlign="center" >
                {errorCode === null ? `Genera una nueva contraseña` : `Error`}
              </Typography>
            </Box>

            <Box >
              <Typography fontSize="16px" fontWeight="300" textAlign="justify">
                {errorCode !== null && getErrorMessage()}
                {errorCode === null && !hasSuccedeed && `Escribe tu nueva contraseña`}
                {errorCode === null && hasSuccedeed && `Cambiaste tu contraseña con éxito`}
              </Typography>
            </Box>

            {(hasSuccedeed === false && errorCode === null) && (
              <>
                <Box sx={{ marginTop: `20px` }} >
                  <TextField
                    id='reset-password'
                    error={newPasswordError}
                    placeholder="Contraseña"
                    type='password'
                    isDisabled={isLoading}
                    value={newPassword}
                    onChange={(v) => {
                      if (newPasswordError !== ``) setNewPasswordError(``)
                      setNewPassword(v)
                    }}
                  />
                </Box>

                <Box sx={{ marginTop: `5px`, marginLeft: `5px` }} >
                  <Typography fontWeight="300" >
                    La contraseña debe tener mínimo 8 caracteres
                  </Typography>
                </Box>

                <Box sx={{ marginTop: `5px` }} >
                  <TextField
                    id='reset-password-confirmation'
                    error={newPasswordConfirmationError}
                    placeholder="Confirma tu contraseña"
                    type='password'
                    isDisabled={isLoading}
                    value={newPasswordConfirmation}
                    onChange={(v) => setNewPasswordConfirmation(v)}
                  />
                </Box>

                <Box sx={{ marginTop: `30px` }} >
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={resetPassword}
                    disabled={newPassword.length === 0 || newPasswordConfirmation.length === 0 || newPasswordError !== `` || newPasswordConfirmationError !== ``}
                  >
                    Envia
                  </Button>
                </Box>

              </>
            )}

            {errorCode !== null && (
              <>
                <Box sx={{ marginTop: `30px`, width: `100%` }} >
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={() => navigate(`/login`)}
                  >
                    Regresar
                  </Button>
                </Box>
              </>
            )}

            {hasSuccedeed === true && (
              <>
                <Box sx={{ marginTop: `30px` }} >
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={() => navigate(`/login`)}
                  >
                    Iniciar sesión
                  </Button>
                </Box>
              </>)}

          </Box>
        </Box>

      </Box>

    </>

  )
}

export default ResetPassword
