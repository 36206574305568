
import React from 'react'
import Markdown from 'react-markdown'
import RightImage from 'src/assets/lesson-plan/right-image.png'

import { Box, Typography } from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import { AI_CHAT_MESSAGE_TYPE, LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS, LESSON_PLAN_EXECUTION_FEEDBACK } from '@instruia/utils'
import ChatBar from 'src/components/chat-bar'
import { ILessonPlanExecutionChatMessage } from 'src/entities/lesson-plan/execution/chat-message/types'
import { useIsMobile } from 'src/modules/utils'

interface IProps {
  isLoading: boolean
  hasExecuted: boolean
  onCopyToClipboard: () => void
  executionResult: string | null
  executionFeedback: LESSON_PLAN_EXECUTION_FEEDBACK
  onFeedbackGiven: (f: LESSON_PLAN_EXECUTION_FEEDBACK) => void
  chatMessageContent: string
  setChatMessageContent: (m: string) => void
  onSendChatMessage: () => void
  chatMessageExecutionStatus: LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS | null
  chatMessages: ILessonPlanExecutionChatMessage[]
}
export function ExecutionView (props: IProps) {
  const isMobile = useIsMobile()
  const selectedStyle = { color: `primary.main` }

  return (
    <>
      {!props.hasExecuted && !isMobile && (
        <Box sx={{ flexGrow: 1, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
          <img src={RightImage} />
        </Box>
      )}
      {props.hasExecuted && (
        <Box sx={{ padding: `20px` }}>
          <Box display="flex" flexDirection="row-reverse" gap="8px" alignItems="center">
            <ContentCopyIcon
              sx={{
                cursor: `pointer`,
                '&:hover': selectedStyle
              }}
              onClick={props.onCopyToClipboard}
            />
            {/* |
            <DownloadForOfflineOutlinedIcon
              sx={{
                cursor: `pointer`,
                '&:hover': selectedStyle
              }}
            /> */}
            |
            <ThumbDownOffAltIcon
              sx={{
                cursor: `pointer`,
                '&:hover': selectedStyle,
                ...(props.executionFeedback === LESSON_PLAN_EXECUTION_FEEDBACK.DISLIKED ? selectedStyle : {})
              }}
              onClick={() => props.onFeedbackGiven(LESSON_PLAN_EXECUTION_FEEDBACK.DISLIKED)}
            />
            |
            <ThumbUpOffAltIcon
              sx={{
                cursor: `pointer`,
                '&:hover': selectedStyle,
                ...(props.executionFeedback === LESSON_PLAN_EXECUTION_FEEDBACK.LIKED ? selectedStyle : {})
              }}
              onClick={() => props.onFeedbackGiven(LESSON_PLAN_EXECUTION_FEEDBACK.LIKED)}
            />
          </Box>

          <Markdown >
            {props.executionResult}
          </ Markdown>

          {
            props.chatMessages.length !== 0 && (
              <Box sx={{ marginTop: `20px` }}>
                <Typography fontSize="24px" fontWeight="600">Chat</Typography>
                <Box sx={{ marginTop: `10px`, display: `flex`, flexDirection: `column`, gap: `10px` }}>
                  {props.chatMessages.map(c => {
                    return (
                      <Typography>
                        <b>{c.type === AI_CHAT_MESSAGE_TYPE.HUMAN ? `Tú: ` : `Instruia: `}</b>
                        {c.content}
                      </Typography>
                    )
                  })}
                  {(props.chatMessageExecutionStatus === LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.QUEUED ||
                    props.chatMessageExecutionStatus === LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.RUNNING) && (
                      <Typography fontWeight="300">Cargando...</Typography>
                  )}
                </Box>
              </Box>
            )
          }
          <Box sx={{ marginTop: `20px` }}>
            <ChatBar
              isDisabled={props.isLoading || props.chatMessageExecutionStatus === LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.QUEUED || props.chatMessageExecutionStatus === LESSON_PLAN_EXECUTION_CHAT_MESSAGE_EXECUTION_STATUS.RUNNING}
              placeholder="Pide lo que necesites cambiar"
              value={props.chatMessageContent}
              setValue={props.setChatMessageContent}
              onSubmit={props.onSendChatMessage}
            />
          </Box>
        </Box>
      )}

    </>
  )
}
