import { Box, Typography } from '@mui/material'
import React from 'react'
import { ITeacher } from 'src/entities/teacher/types'

interface IProps {
  teacher: ITeacher
}

export default function Intro (props: IProps) {
  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, alignItems: `flex-end`, rowGap: `30px`, marginBottom: `50px` }}>
      <ChatMessage message={`¡Hola ${props.teacher.firstName}!`} />
      <ChatMessage message='¿Cómo puedo ayudarte hoy?' />
    </Box>
  )
}

interface IMProps {
  message: string
}
function ChatMessage (props: IMProps) {
  return (
    <Box sx={{
      backgroundColor: `secondary.main`,
      color: `white`,
      borderRadius: `32px 32px 0px 32px`,
      padding: `12px 18px 12px 18px`
    }}>
      <Typography fontSize="20px" fontWeight="600">
        {props.message}
      </Typography>
    </Box>
  )
}
