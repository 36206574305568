import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import LogoSmall from 'src/components/logo'

export default function MobileAppbar () {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  function onLogout () {
    localStorage.clear()
    location.assign(`/`)
  }

  const settings = [
    // `Profile`, `Account`, `Dashboard`,
    { label: `Logout`, handler: onLogout }]

  return (
    <AppBar position="static" sx={{ backgroundColor: `#FEFEFE`, color: `black` }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box
        sx={{ cursor: `pointer` }}
        onClick={() => location.assign(`/`) }
      >
        <LogoSmall />
      </Box>

          <Box sx={{ flexGrow: 1, display: { xs: `flex`, md: `none` } }}>

          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: `45px` }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: `top`,
                horizontal: `right`
              }}
              keepMounted
              transformOrigin={{
                vertical: `top`,
                horizontal: `right`
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={setting.handler}>
                  <Typography sx={{ textAlign: `center` }}>{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
