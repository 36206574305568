
import { STUDENT_REPORT_STEP, SCHOOL_GRADE, SCHOOL_SUBJECT } from '@instruia/utils'
import axios from '../../../modules/axios'
import { IStudentReport, IAggregatedStudentReport } from '../types'
import { IStudentReportExecution } from 'src/entities/student-report/execution/types'

const PATH = `student-reports`

export async function createStudentReport (
  grade: SCHOOL_GRADE | null,
  pronouns: string | null,
  strengths: string | null,
  opportunities: string | null,
  step: STUDENT_REPORT_STEP
): Promise<IStudentReport> {
  const response = await axios({
    method: `post`,
    url: `/${PATH}`,
    data: {
      grade,
      pronouns,
      strengths,
      opportunities,
      step
    }
  })

  return response.data
}

export async function getStudentReport (studentReportId: string): Promise<IAggregatedStudentReport> {
  const response = await axios({
    method: `get`,
    url: `/${PATH}/${studentReportId}`
  })

  return response.data
}

export async function updateStudentReport (
  studentReportId: string,
  grade: SCHOOL_GRADE | null,
  pronouns: string | null,
  strengths: string | null,
  opportunities: string | null,
  // if null then do not update step
  step: STUDENT_REPORT_STEP | null,
  shouldExecute: boolean
): Promise<IAggregatedStudentReport> {
  const response = await axios({
    method: `put`,
    url: `/${PATH}/${studentReportId}`,
    data: {
      grade,
      pronouns,
      strengths,
      opportunities,
      step,
      shouldExecute
    }
  })

  return response.data
}

export async function getStudentReportCurrentExecution (studentReportId: string): Promise<IStudentReportExecution> {
  const response = await axios({
    method: `get`,
    url: `/${PATH}/${studentReportId}/executions/current`
  })

  return response.data
}
