import React, { ReactNode } from 'react'

import { Modal, Box, Typography, Button } from '@mui/material'
import { useIsMobile } from 'src/modules/utils'

interface IProps {
  children: ReactNode;
  title: string
  isButtonDisabled: boolean
  isEditExecutionModalOpen: boolean
  setIsEditExecutionModalOpen: (c: boolean) => void
  onSubmitChanges: () => void
}

const PADDING = 20

export default function EditExecutionModal (props: IProps) {
  const isMobile = useIsMobile()

  return (
    <Modal
      open={props.isEditExecutionModalOpen}
      onClose={() => props.setIsEditExecutionModalOpen(false)}
    >
      <Box
        sx={{
          position: `absolute`,
          top: `calc(50% - ${PADDING}px)`,
          left: `calc(50% - ${PADDING}px)`,
          transform: `translate(-50%, -50%)`,
          width: `calc(100% - 80px)`,
          maxWidth: `650px`,
          margin: `${PADDING}px`,
          bgcolor: `background.paper`,
          borderRadius: `8px`,
          padding: `${PADDING}px`,
          display: `flex`,
          flexDirection: `column`,
          gap: isMobile ? `20px` : `50px`
        }}
      >
        <Typography fontWeight="600" fontSize="24px" >
          {props.title}
        </Typography>

       {props.children}

        <Button
          disabled={props.isButtonDisabled}
          variant='contained'
          onClick={props.onSubmitChanges}
        >
          Guardar y regenerar
        </Button>
      </Box>
    </Modal>
  )
}
