
import React from "react"

import { Box } from '@mui/material'
import { LESSON_PLAN_STEP, SCHOOL_GRADE, SCHOOL_SUBJECT } from "@instruia/utils"

import NewLesson from "./new-lesson"
import ExecutedLesson from "./executed-lesson"
import Header from '../../../components/feature/header'
import PlanearClaseIcon from 'src/assets/home/planear_clase_icon.png'
import { useIsMobile } from "src/modules/utils"

interface IProps {
  isLoading: boolean
  uiStep: LESSON_PLAN_STEP
  setUIStep: (s: LESSON_PLAN_STEP) => void
  onBack: () => void
  subject: SCHOOL_SUBJECT | null
  setSubject: (s: SCHOOL_SUBJECT) => void
  theme: string
  setTheme: (t: string) => void
  grade: SCHOOL_GRADE | null
  setGrade: (t: SCHOOL_GRADE) => void
  additionalDetails: string
  setAdditionalDetails: (d: string) => void
  onContinueStep: () => void
  onSkipStep: () => void
  hasExecuted: boolean
  onEditLessonPlanClicked: () => void
}

export default function LessonEditor (props: IProps) {
  function getPercentage () {
    if (props.uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1) return 20
    if (props.uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2) return 40
    if (props.uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3) return 60
    if (props.uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4) return 80
    if (props.uiStep === LESSON_PLAN_STEP.DONE_5) return 100

    return 100
  }

  const isMobile = useIsMobile()
  const percentage = getPercentage()
  const isDone = props.uiStep === LESSON_PLAN_STEP.DONE_5

  return (
    <Box sx={{ flexGrow: 1, display: `flex`, flexDirection: `column`, padding: isMobile ? `0 0 25px 0px` : `25px`, gap: `25px`, maxWidth: `800px` }} >
      <Header
         percentage={percentage}
         isDone={isDone}
         shouldDisplayBackButton={props.uiStep !== LESSON_PLAN_STEP.SELECT_SUBJECT_1 && props.uiStep !== LESSON_PLAN_STEP.DONE_5}
         onBack={props.onBack}
         isLoading={props.isLoading}
         icon={PlanearClaseIcon}
         title={`Planear clase`}
         subtitle={`Genera tu plan de clase`}
      />
      {props.hasExecuted
        ? (
          <ExecutedLesson
            isLoading={props.isLoading}
            subject={props.subject}
            setSubject={props.setSubject}
            theme={props.theme}
            setTheme={props.setTheme}
            grade={props.grade}
            setGrade={props.setGrade}
            additionalDetails={props.additionalDetails}
            setAdditionalDetails={props.setAdditionalDetails}
            onEditLessonPlanClicked={props.onEditLessonPlanClicked}
          />
          )
        : (
          <NewLesson
            isLoading={props.isLoading}
            uiStep={props.uiStep}
            setUIStep={props.setUIStep}
            subject={props.subject}
            setSubject={props.setSubject}
            theme={props.theme}
            setTheme={props.setTheme}
            grade={props.grade}
            setGrade={props.setGrade}
            additionalDetails={props.additionalDetails}
            setAdditionalDetails={props.setAdditionalDetails}
            onContinueStep={props.onContinueStep}
            onSkipStep={props.onSkipStep}
          />
          )}

    </Box>
  )
}
