import { SCHOOL_GRADE, SCHOOL_SUBJECT, mapSubjectCodeIntoTitle, mapGradeCodeIntoTitle } from '@instruia/utils'
import { Box, Typography } from '@mui/material'
import React from 'react'
import ExecutedLessonCard from './card'
import * as utils from 'src/modules/utils'
import EditNoteIcon from '@mui/icons-material/EditNote'

interface IProps {
  isLoading: boolean
  subject: SCHOOL_SUBJECT | null
  setSubject: (s: SCHOOL_SUBJECT) => void
  theme: string
  setTheme: (t: string) => void
  grade: SCHOOL_GRADE | null
  setGrade: (t: SCHOOL_GRADE) => void
  additionalDetails: string
  setAdditionalDetails: (d: string) => void
  onEditLessonPlanClicked: () => void
}
export default function ExecutedLesson (props: IProps) {
  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, gap: `20px`, alignItems: `stretch` }}>
      <Box sx={{ display: `flex`, justifyContent: `space-between` }}>
        <Typography fontSize="20px" fontWeight="600">
          Resumen
        </Typography>
        <Box
          onClick={props.onEditLessonPlanClicked}
          sx={{ display: `flex`, gap: `4px`, cursor: `pointer` }}
        >
          <EditNoteIcon color='primary' />
          <Typography
            textAlign="end"
            sx={{ fontSize: `14px`, ...utils.textLinkStyle }}
          >
            Editar
          </Typography>
        </Box>
      </Box>
      <ExecutedLessonCard title='Materia' content={props.subject !== null ? mapSubjectCodeIntoTitle(props.subject) : ``} />
      <ExecutedLessonCard title='Tema' content={props.theme ?? ``} />
      <ExecutedLessonCard title='Grado' content={props.grade !== null ? mapGradeCodeIntoTitle(props.grade) : ``} />
      <ExecutedLessonCard
        title='Detalles adicionales'
        content={props.additionalDetails === null || props.additionalDetails === `` ? `No especifica` : props.additionalDetails} />
    </Box>
  )
}
