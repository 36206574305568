import React from 'react'

import TextField from 'src/components/text-field'

interface IProps {
  isLoading: boolean
  exerciseCount: number
  setExerciseCount: (n: number) => void
  onContinueStep?: () => void
}
export default function SelectExerciseCountStep3 (props: IProps) {
  return (
    <TextField
      type='number'
      placeholder=""
      isDisabled={props.isLoading}
      value={String(props.exerciseCount)}
      onChange={(v) => {
        const vNum = Number(v)
        if (Number.isNaN(vNum)) return
        props.setExerciseCount(vNum)
      }}
      onShiftEnter={() => {
        if (props.onContinueStep === undefined) return
        props.onContinueStep()
      }}
    />
  )
}
