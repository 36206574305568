import React from 'react'

import { Box, Button, Typography } from '@mui/material'
import { WORKSHEET_STEP, SCHOOL_GRADE } from "@instruia/utils"

import * as utils from 'src/modules/utils'
import DescribeThemeStep1 from '../steps/1-describe-theme'
import SelectGradeStep2 from '../steps/2-select-grade'
import SelectExerciseCountStep3 from '../steps/3-select-exercise-count'

interface IProps {
  isLoading: boolean
  uiStep: WORKSHEET_STEP
  setUIStep: (s: WORKSHEET_STEP) => void
  theme: string
  setTheme: (t: string) => void
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
  exerciseCount: number
  setExerciseCount: (c: number) => void
  onContinueStep: () => void
  onSkipStep: () => void
}
export default function NewLesson (props: IProps) {
  function isContinueButtonDisabled () {
    if (props.isLoading) return true

    if (props.uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1) {
      if (props.theme === null || props.theme === ``) return true
      return false
    }

    if (props.uiStep === WORKSHEET_STEP.SELECT_GRADE_2) {
      if (props.grade === null) return true
      return false
    }
    return false
  }

  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, gap: `15px`, alignItems: `stretch` }}>
      <Typography fontSize="20px" fontWeight="600">
        {props.uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1 && `Describe el tema`}
        {props.uiStep === WORKSHEET_STEP.SELECT_GRADE_2 && `Selecciona el grado`}
        {props.uiStep === WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3 && `Selecciona el número de ejercicios`}
      </Typography>

      {props.uiStep === WORKSHEET_STEP.DESCRIBE_THEME_1 && (
        <DescribeThemeStep1
          isLoading={props.isLoading}
          theme={props.theme}
          setTheme={props.setTheme}
          onContinueStep={props.onContinueStep}
        />
      )}

      {props.uiStep === WORKSHEET_STEP.SELECT_GRADE_2 && (
        <SelectGradeStep2
          isDisabled={props.isLoading}
          grade={props.grade}
          setGrade={props.setGrade}
        />
      )}

      {props.uiStep === WORKSHEET_STEP.SELECT_EXERCISE_COUNT_3 && (
        <SelectExerciseCountStep3
          isLoading={props.isLoading}
          exerciseCount={props.exerciseCount}
          setExerciseCount={props.setExerciseCount}
          onContinueStep={props.onContinueStep}
        />
      )}

      <Box sx={{ display: `flex`, justifyContent: `end`, gap: `25px`, alignItems: `center` }}>
        <Typography
          textAlign="end"
          sx={{ fontSize: `14px`, ...utils.textLinkStyle }}
          onClick={props.onSkipStep}
        >
          {props.uiStep === WORKSHEET_STEP.SELECT_GRADE_2 && `Saltar paso`}
        </Typography>
        <Button
          disabled={isContinueButtonDisabled()}
          variant='contained'
          onClick={props.onContinueStep}>
          Siguiente
        </Button>
      </Box>
    </Box >
  )
}
