import React from 'react'

import { Box, Button, Typography } from '@mui/material'
import { STUDENT_REPORT_STEP, SCHOOL_GRADE, SCHOOL_SUBJECT } from "@instruia/utils"

import * as utils from 'src/modules/utils'

import SelectGradeStep1 from '../steps/1-select-grade'
import SetPronounsStep2 from '../steps/2-set-pronouns'
import SetStrengthsStep3 from '../steps/3-set-strengths'
import SetOpportunitiesStep4 from '../steps/4-set-opportunities'

interface IProps {
  isLoading: boolean
  uiStep: STUDENT_REPORT_STEP
  setUIStep: (s: STUDENT_REPORT_STEP) => void
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
  pronouns: string
  setPronouns: (s: string) => void
  strengths: string
  setStrengths: (s: string) => void
  opportunities: string
  setOpportunities: (o: string) => void
  onContinueStep: () => void
  onSkipStep: () => void
}
export default function NewLesson (props: IProps) {
  function isContinueButtonDisabled () {
    if (props.isLoading) return true

    return false
  }

  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, gap: `15px`, alignItems: `stretch` }}>
      <Typography fontSize="20px" fontWeight="600">
        {props.uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1 && `Selecciona el grado`}
        {props.uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2 && `¿Cuál es el nombre del estudiante?`}
        {props.uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3 && `Describe las fortalezas`}
        {props.uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4 && `Describe las oportunidades de mejora`}
      </Typography>

      {props.uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1 && (
        <SelectGradeStep1
          isDisabled={props.isLoading}
          grade={props.grade}
          setGrade={props.setGrade}
        />

      )}

      {props.uiStep === STUDENT_REPORT_STEP.SET_PRONOUNS_2 && (
        <SetPronounsStep2
          isLoading={props.isLoading}
          pronouns={props.pronouns}
          setPronouns={props.setPronouns}
          onContinueStep={props.onContinueStep}
        />
      )}

      {props.uiStep === STUDENT_REPORT_STEP.SET_STRENGTHS_3 && (
        <SetStrengthsStep3 isLoading={props.isLoading} strengths={props.strengths} setStrengths={props.setStrengths} />
      )}

      {props.uiStep === STUDENT_REPORT_STEP.SET_OPPORTUNITIES_4 && (
        <SetOpportunitiesStep4 isLoading={props.isLoading} opportunities={props.opportunities} setOpportunities={props.setOpportunities} />
      )}
      <Box sx={{ display: `flex`, justifyContent: `end`, gap: `25px`, alignItems: `center` }}>
        <Typography
          textAlign="end"
          sx={{ fontSize: `14px`, ...utils.textLinkStyle }}
          onClick={props.onSkipStep}
        >
          {props.uiStep === STUDENT_REPORT_STEP.SELECT_GRADE_1 && `Saltar paso`}
        </Typography>
        <Button
          disabled={isContinueButtonDisabled()}
          variant='contained'
          onClick={props.onContinueStep}>
          Siguiente
        </Button>
      </Box>
    </Box >
  )
}
