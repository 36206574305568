import React from 'react'

import { SCHOOL_GRADE, SCHOOL_SUBJECT } from '@instruia/utils'

import EditExecutionModal from 'src/components/feature/edit-execution-modal'

import SelectSubjectStep1 from '../lesson-editor/steps/1-select-subject'
import DescribeThemeStep2 from '../lesson-editor/steps/2-describe-theme'
import SelectGradeStep3 from '../lesson-editor/steps/3-select-grade'
import AdditionalDetailsStep4 from '../lesson-editor/steps/4-additional-details'

interface IProps {
  isLoading: boolean
  isEditLessonPlanModalOpen: boolean
  setIsEditLessonPlanModalOpen: (c: boolean) => void
  editedExecutionSubject: SCHOOL_SUBJECT | null
  setEditedExecutionSubject: (s: SCHOOL_SUBJECT) => void
  editedExecutionTheme: string
  setEditedExecutionTheme: (t: string) => void
  editedExecutionGrade: SCHOOL_GRADE | null
  setEditedExecutionGrade: (g: SCHOOL_GRADE) => void
  editedExecutionAdditionalDetails: string
  setEditedExecutionAdditionalDetails: (d: string) => void
  subject: SCHOOL_SUBJECT | null
  theme: string
  grade: SCHOOL_GRADE | null
  additionalDetails: string
  onSubmitChanges: () => void
}

export default function EditLessonPlanModal (props: IProps) {
  function getIsButtonDisabled (): boolean {
    if (props.isLoading) return true

    if (props.editedExecutionSubject !== props.subject) return false
    if (props.editedExecutionTheme !== props.theme) return false
    if (props.editedExecutionGrade !== props.grade) return false
    if (props.editedExecutionAdditionalDetails !== props.additionalDetails) return false
    return true
  }

  const isButtonDisabled = getIsButtonDisabled()

  return (
    <EditExecutionModal
      title="Planear clase"
      isButtonDisabled={isButtonDisabled}
      isEditExecutionModalOpen={props.isEditLessonPlanModalOpen}
      setIsEditExecutionModalOpen={props.setIsEditLessonPlanModalOpen}
      onSubmitChanges={props.onSubmitChanges}
    >
      <SelectSubjectStep1
        isDisabled={props.isLoading}
        subject={props.editedExecutionSubject}
        setSubject={props.setEditedExecutionSubject}
      />
      <DescribeThemeStep2
        isLoading={props.isLoading}
        theme={props.editedExecutionTheme}
        setTheme={props.setEditedExecutionTheme}
      />
      <SelectGradeStep3
        isDisabled={props.isLoading}
        grade={props.editedExecutionGrade}
        setGrade={props.setEditedExecutionGrade}
      />
      <AdditionalDetailsStep4
        isLoading={props.isLoading}
        additionalDetails={props.editedExecutionAdditionalDetails}
        setAdditionalDetails={props.setEditedExecutionAdditionalDetails}
      />
    </EditExecutionModal>
  )
}
