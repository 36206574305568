import { Box, Typography } from '@mui/material'
import React from 'react'

interface IProps {
  title: string
  content: string
}
export default function ExecutedLessonCard (props: IProps) {
  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, padding: `12px`, backgroundColor: `#FFFFFF` }}>
      <Typography fontWeight="700" fontSize="16px" >
        {props.title}
      </Typography>
      <Typography fontWeight="400" fontSize="16px" >
        {props.content}
      </Typography>
    </Box>
  )
}
