import React from 'react'

import { useNavigate } from 'react-router-dom'
import { Typography, Box, useMediaQuery } from '@mui/material'
import Intro from './intro'
import { useTeacherState } from 'src/context/teacher'
import { useTheme } from '@mui/material/styles'

import TopBar from '../../components/top-bar'

import ClaseIcon from '../../assets/home/clase_icon.png'
import CalificacionIcon from '../../assets/home/calificacion_icon.png'
import PlanearClaseIcon from 'src/assets/home/planear_clase_icon.png'
import CrearEjerciciosIcon from '../../assets/home/crear_ejercicios_icon.png'
import BoletinDeCalificacionIcon from '../../assets/home/boletin_de_calificacion_icon.png'
import CrearExamenIcon from '../../assets/home/crear_examen_icon.png'

import RightArrowIcon from '../../assets/main/arrow-right.png'
import ChatBar from '../../components/chat-bar'
import SideBar from './side-bar'

const INFO = [{
  title: `Clase`,
  description: `Encuentra aquí herramientas para generar contenido para tu clase`,
  icon: ClaseIcon,
  sections: [
    { name: `Planear clase`, description: `Genera tu plan de clase`, icon: PlanearClaseIcon, path: `/lesson-plans` },
    { name: `Crear ejercicios`, description: `Genera ejercicios para realizar en clase`, icon: CrearEjerciciosIcon, path: `/worksheets` }
  ]
}, {
  title: `Reportes`,
  description: `Encuentra aquí herramientas para generar en minutos reportes a estudiantes`,
  icon: CalificacionIcon,
  sections: [
    { name: `Reporte del estudiante`, description: `Escribe en segundos el reporte de actitudes, fortalezas y oportunidades del estudiante`, icon: BoletinDeCalificacionIcon, path: `/student-reports` },
    { name: `Responde a Padres`, description: `Genera respuesta a emails o mensajes de padres`, icon: CrearExamenIcon, path: null }
  ]
}]

function Home () {
  const stylesTheme = useTheme()

  const teacherState = useTeacherState()
  const navigate = useNavigate()

  const doesItHaveSidebar = useMediaQuery(stylesTheme.breakpoints.up(`md`))

  const drawerWidth = doesItHaveSidebar ? 260 : 0

  console.log(`doesItHaveSidebar`, doesItHaveSidebar)

  function renderLine ({ title, description, icon, sections }: { title: string, description: string, icon: any, sections: { name: string, description: string, icon: any, path: string | null, }[] }) {
    return (
      <Box
        key={title}
        sx={{
          display: `flex`,
          flexDirection: `row`,
          gap: `20px`
          // backgroundColor: `#bce8ac` // VERDE - CONTENEDOR DE LINEA
        }}
      >
        {doesItHaveSidebar && (
          <Box sx={{
            backgroundColor: `#ECDDFF`,
            padding: `10px`,
            borderRadius: `14px`,
            display: `flex`,
            flexDirection: `column`,
            gap: `5px`,
            maxWidth: `140px`,
            alignSelf: `flex-start`
          }}>
            <img src={icon} width="16px" height="20px" style={{ padding: `4px 8px 4px 8px` }} />
            <Typography color="#5002B3" fontSize="20px" fontWeight="600">
              {title}
            </Typography>
            <Typography color="#5002B3" fontSize="14px" fontWeight="400">
              {description}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            flexGrow: 1,
            display: `flex`,
            flexFlow: `row wrap`,
            gap: `20px`
            // backgroundColor: `#e4ace8` // ROSA - CONTENEDOR DE ACTIVIDADES
          }}>
          {sections.map((s) => {
            return (
              <Box
                key={s.name}
                sx={{
                  flexGrow: 1,
                  backgroundColor: `#FFFFFF`,
                  padding: `10px 18px 18px 10px`,
                  borderRadius: `14px`,
                  cursor: s.path !== null ? `pointer` : `auto`
                }}
                onClick={() => s.path !== null && navigate(s.path)}
              >
                <Box sx={{ display: `flex`, justifyContent: `space-between`, alignItems: `flex-start` }}>
                  <img src={s.icon} height="50" width="50" />
                  {s.path === null && (
                    <Box sx={{ padding: `4px 12px`, backgroundColor: `#C9CDDB`, borderRadius: `8px`, display: `flex`, alignItems: `center` }}>
                      <Typography fontWeight="300" fontSize="14px">
                        Próximamente
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Typography fontSize="24px" fontWeight="600" sx={{ width: `270px` }}>
                  {s.name}
                </Typography>

                <Box sx={{ display: `flex`, flexDirection: `row`, justifyContent: `space-between`, alignItems: `flex-end` }}>
                  <Typography textAlign="justify" fontSize="14px" fontWeight="400" sx={{ width: `270px` }}>
                    {s.description}
                  </Typography>
                  <img src={RightArrowIcon} />
                </Box>
              </Box>
            )
          })}
        </Box>

      </Box>
    )
  }

  const teacher = teacherState.teacher!

  return (
    <>
      {doesItHaveSidebar && <SideBar drawerWidth={drawerWidth} />}
      <Box
        sx={{
          minHeight: `calc(100vh - 40px)`,
          backgroundColor: `#EAF0F3`,
          marginLeft: `${drawerWidth}px`,
          padding: `20px`,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `space-between`,
          gap: `20px`
        }}>
        {/* <TopBar teacher={teacher} /> */}
        <Box>{/* This is just for the "space-between-layout" */}</Box>

        <Box sx={{ maxWidth: `1000px`, width: `100%`, padding: `50px 0 50px 0` }}>
          <Intro teacher={teacher} />

          <Box
            sx={{
              // backgroundColor: `#acc3e8`, // AZUL - CONTENEDOR DE TODO
              display: `flex`,
              flexDirection: `column`,
              alignItems: `stretch`,
              gap: `20px`
            }}>
            {INFO.map(renderLine)}
          </Box>
        </Box>

        <Box></Box>
        {/* <ChatBar placeholder="Pide lo que necesites" value='' setValue={() => {}} onSubmit={() => {}} /> */}
      </Box>
    </>

  )
}

export default Home
