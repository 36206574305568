import React from 'react'

import TextField from 'src/components/text-field'

interface IProps {
  isLoading: boolean
  pronouns: string
  setPronouns: (p: string) => void
  onContinueStep?: () => void
}
export default function SetPronounsStep2 (props: IProps) {
  return (
    <TextField
      placeholder="El nombre por el cual vas a llamar al estudiante, por ejemplo él, o Daniel"
      isDisabled={props.isLoading}
      value={props.pronouns}
      onChange={props.setPronouns}
      multiline
      minRows={4}
      maxRows={10}
      onShiftEnter={() => {
        if (props.onContinueStep === undefined) return

        if (props.pronouns === ``) return
        props.onContinueStep()
      }}
    />
  )
}
