import React from 'react'

import SelectSubjectStep1 from '../steps/1-select-subject'

import { Box, Button, Typography } from '@mui/material'
import { LESSON_PLAN_STEP, SCHOOL_GRADE, SCHOOL_SUBJECT } from "@instruia/utils"

import * as utils from 'src/modules/utils'
import DescribeThemeStep2 from '../steps/2-describe-theme'
import SelectGradeStep3 from '../steps/3-select-grade'
import AdditionalDetailsStep4 from '../steps/4-additional-details'

interface IProps {
  isLoading: boolean
  uiStep: LESSON_PLAN_STEP
  setUIStep: (s: LESSON_PLAN_STEP) => void
  subject: SCHOOL_SUBJECT | null
  setSubject: (s: SCHOOL_SUBJECT) => void
  theme: string
  setTheme: (t: string) => void
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
  additionalDetails: string
  setAdditionalDetails: (d: string) => void
  onContinueStep: () => void
  onSkipStep: () => void
}
export default function NewLesson (props: IProps) {
  function isContinueButtonDisabled () {
    if (props.isLoading) return true

    if (props.uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1) {
      if (props.subject === null) return true
      return false
    }

    if (props.uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2) {
      if (props.theme === null || props.theme === ``) return true
      return false
    }
    return false
  }

  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, gap: `15px`, alignItems: `stretch` }}>
      <Typography fontSize="20px" fontWeight="600">
        {props.uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1 && `Selecciona la materia`}
        {props.uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2 && `Describe el tema de la clase`}
        {props.uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3 && `Selecciona el grado`}
        {props.uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4 && `Añade detalles adicionales`}
      </Typography>

      {props.uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1 && (
        <SelectSubjectStep1 isDisabled={props.isLoading} subject={props.subject} setSubject={props.setSubject} />
      )}

      {props.uiStep === LESSON_PLAN_STEP.DESCRIBE_LESSON_THEME_2 && (
        <DescribeThemeStep2
          isLoading={props.isLoading}
          theme={props.theme}
          setTheme={props.setTheme}
          onContinueStep={props.onContinueStep}
        />
      )}

      {props.uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3 && (
        <SelectGradeStep3
          isDisabled={props.isLoading}
          grade={props.grade}
          setGrade={props.setGrade}
        />
      )}

      {props.uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4 && (
        <AdditionalDetailsStep4
          isLoading={props.isLoading}
          additionalDetails={props.additionalDetails}
          setAdditionalDetails={props.setAdditionalDetails}
          onContinueStep={props.onContinueStep}
          onSkipStep={props.onSkipStep}
        />
      )}
      <Box sx={{ display: `flex`, justifyContent: `end`, gap: `25px`, alignItems: `center` }}>
        <Typography
          textAlign="end"
          sx={{ fontSize: `14px`, ...utils.textLinkStyle }}
          onClick={props.onSkipStep}
        >
          {props.uiStep === LESSON_PLAN_STEP.SELECT_SUBJECT_1 && `Saltar paso`}
          {props.uiStep === LESSON_PLAN_STEP.SELECT_GRADE_3 && `Saltar paso`}
          {props.uiStep === LESSON_PLAN_STEP.MORE_DETAILS_4 && `Saltar paso`}
        </Typography>
        <Button
          disabled={isContinueButtonDisabled()}
          variant='contained'
          onClick={props.onContinueStep}>
          Siguiente
        </Button>
      </Box>
    </Box >
  )
}
