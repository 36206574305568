import React from 'react'

import TextField from 'src/components/text-field'

interface IProps {
  isLoading: boolean
  strengths: string
  setStrengths: (s: string) => void
  onContinueStep?: () => void
  onSkipStep?: () => void
}
export default function SetStrengthsStep4 (props: IProps) {
  return (
    <TextField
      placeholder="Describe las fortalezas del estudiante"
      isDisabled={props.isLoading}
      value={props.strengths}
      onChange={props.setStrengths}
      multiline
      minRows={4}
      maxRows={10}
      onShiftEnter={() => {
        if (props.strengths === ``) {
          if (props.onSkipStep !== undefined) {
            props.onSkipStep()
            return
          }
        }

        if (props.onContinueStep !== undefined) {
          props.onContinueStep()
        }
      }}
    />
  )
}
