/* eslint-disable no-useless-catch */
import { Button, Container, Divider, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import * as yup from 'yup'

import InTextField from '../../components/text-field'

import * as teacherService from '../../entities/teacher/service'

import * as CONSTANTS from '../../config/constants'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { ERROR_CODE } from '@instruia/utils'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2

import Circle from '../../assets/circle.png'
import Logo from 'src/components/logo'

import { ANALYTICS_EVENTS } from '../../modules/analytics/analytics-events'
import * as analytics from '../../modules/analytics'
import { useIsMobile } from 'src/modules/utils'

enum STAGE {
  LOGIN = `LOGIN`,
  REGISTER = `REGISTER`,
  REGISTER_CONFIRMATION = `REGISTER_CONFIRMATION`,
  RESET_PASSWORD = `RESET_PASSWORD`,
  RESET_PASSWORD_CONFIRMATION = `RESET_PASSWORD_CONFIRMATION`
}

const emailSchema = yup.string().min(1).email().nonNullable()
const nameSchena = yup.string().min(1).nonNullable()

function Login () {
  const isMobile = useIsMobile()

  const [stage, setStage] = useState<STAGE>(STAGE.REGISTER)
  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState(``)
  const [password, setPassword] = useState(``)

  const [loginEmailError, setLoginEmailError] = useState(``)
  const [loginError, setLoginError] = useState(``)

  const [registeringFirstName, setRegisteringFirstName] = useState(``)
  const [registeringLastName, setRegisteringLastName] = useState(``)
  const [registeringEmail, setRegisteringEmail] = useState(``)
  const [registeringPassword, setRegisteringPassword] = useState(``)
  const [registeringPasswordConfirmation, setRegisteringPasswordConfirmation] = useState(``)

  const [registeringFirstNameError, setRegisteringFirstNameError] = useState(``)
  const [registeringLastNameError, setRegisteringLastNameError] = useState(``)
  const [registeringEmailError, setRegisteringEmailError] = useState(``)
  const [registeringPasswordError, setRegisteringPasswordError] = useState(``)

  const registeringPasswordConfirmationError = registeringPasswordConfirmation === `` ? `` : registeringPassword === registeringPasswordConfirmation ? `` : `Contraseñas no son iguales`
  const [registeringError, setRegisteringError] = useState(``)

  const [recoveringEmail, setRecoveringEmail] = useState(``)

  const [recoveringEmailError, setRecoveringEmailError] = useState(``)
  const [recoveringPasswordError, setRecoveringPasswordError] = useState(``)

  // const containerStyle = { display: `flex`, justifyContent: `center` }
  const textLinkStyle = { cursor: `pointer`, color: `primary.main`, fontWeight: `600` }

  const isLoggingIn = stage === STAGE.LOGIN
  const isRegistering = stage === STAGE.REGISTER
  const isConfirmatingRegistration = stage === STAGE.REGISTER_CONFIRMATION
  const isResettingPassword = stage === STAGE.RESET_PASSWORD
  const isConfirmatingPasswordReset = stage === STAGE.RESET_PASSWORD_CONFIRMATION

  const areTwoComponents = !isMobile && !isConfirmatingRegistration && !isResettingPassword && !isConfirmatingPasswordReset

  async function onLogin () {
    if (loginError) setLoginError(``)

    try {
      emailSchema.validateSync(email)
    } catch (e) {
      setLoginEmailError(`Email no válido`)
      return
    }

    try {
      setIsLoading(true)
      const response = await teacherService.loginWithEmail(email, password)
      localStorage.setItem(CONSTANTS.LOCAL_STORAGE.JWT, response.token)

      analytics.identifyTeacher(response)
      analytics.track(ANALYTICS_EVENTS.LOGIN_WITH_EMAIL)

      location.assign(`/`)
    } catch (e: any) {
      setIsLoading(false)

      if (e.response && e.response.data) {
        const code = e.response.data.code

        if (code === ERROR_CODE.TEACHER__LOGIN_WITH_PASSWORD__INCORRECT_CREDENTIALS) {
          setLoginError(`Credenciales incorrectas`)
          return
        }
      }
      setLoginError(`Error interno, intenta de nuevo`)
    }
  }

  async function onRegisterWithEmail () {
    let areThereErrors = false

    try {
      nameSchena.validateSync(registeringFirstName)
    } catch (e) {
      setRegisteringFirstNameError(`Nombre no válido`)
      areThereErrors = true
    }

    try {
      nameSchena.validateSync(registeringLastName)
    } catch (e) {
      setRegisteringLastNameError(`Apellido no válido`)
      areThereErrors = true
    }

    try {
      emailSchema.validateSync(registeringEmail)
    } catch (e) {
      setRegisteringEmailError(`Email no válido`)
      areThereErrors = true
    }

    const isPasswordValid = registeringPassword.length >= 8
    if (!isPasswordValid) {
      setRegisteringPasswordError(`Contraseña no válida`)
      areThereErrors = true
    }

    if (registeringPassword !== registeringPasswordConfirmation) areThereErrors = true

    if (areThereErrors) return

    try {
      setIsLoading(true)
      await teacherService.registerWithEmail(registeringEmail, registeringPassword, registeringFirstName, registeringLastName)

      analytics.track(ANALYTICS_EVENTS.REGISTER_WITH_EMAIL)

      setStage(STAGE.REGISTER_CONFIRMATION)
    } catch (e: any) {
      if (e.response !== undefined && e.response.data !== undefined) {
        const code = e.response.data.code
        if (code === ERROR_CODE.TEACHER__REGISTER_WITH_EMAIL__EMAIL_EXISTS) {
          setRegisteringError(`Ya existe un usuario con ese correo electrónico`)
          return
        }
        if (code === ERROR_CODE.TEACHER__REGISTER_WITH_EMAIL__CODE_ALREADY_SENT) {
          setRegisteringError(`Ya se enviaron instrucciones de registro a ese correo, en caso de no haberlas recibido inténtalo de nuevo en 10 minutos`)
          return
        }
      }
      setRegisteringError(`Error interno, intenta de nuevo más tarde`)
      console.log(`e`, e)
    } finally {
      setIsLoading(false)
    }
  }

  function getIsRegisterButtonDisabled () {
    if (isLoading) return true

    if (registeringFirstName === ``) return true
    if (registeringLastName === ``) return true
    if (registeringEmail === ``) return true
    if (registeringPassword === ``) return true
    if (registeringPasswordConfirmation === ``) return true

    if (registeringFirstNameError !== ``) return true
    if (registeringLastNameError !== ``) return true
    if (registeringPasswordConfirmationError !== ``) return true
    if (registeringEmailError !== ``) return true
    if (registeringPasswordError !== ``) return true

    return false
  }

  async function onEnteredGoogle (credentialResponse: CredentialResponse) {
    const googleToken = credentialResponse.credential
    if (googleToken === undefined) return

    const response = await teacherService.enterWithGoogle(googleToken)
    localStorage.setItem(CONSTANTS.LOCAL_STORAGE.JWT, response.token)

    analytics.identifyTeacher(response)
    if (response.isRegistering) analytics.track(ANALYTICS_EVENTS.REGISTER_WITH_GOOGLE)
    else analytics.track(ANALYTICS_EVENTS.LOGIN_WITH_GOOGLE)

    location.assign(`/`)
  }

  async function onRecoverPassword () {
    setIsLoading(true)
    if (recoveringPasswordError) setRecoveringPasswordError(``)

    try {
      emailSchema.validateSync(recoveringEmail)
    } catch (e) {
      setRecoveringEmailError(`Email no válido`)
    }

    try {
      await teacherService.recoverPassword(recoveringEmail)
      setStage(STAGE.RESET_PASSWORD_CONFIRMATION)

      setRecoveringEmail(``)
    } catch (e: any) {
      if (e.response && e.response.data) {
        const code = e.response.data.code
        if (code === ERROR_CODE.TEACHER__RECOVER_PASSWORD__INEXISTING_ACCOUNT) {
          setRecoveringPasswordError(`No existe ninguna cuenta asociada a ese correo electrónico`)
          return
        }
        if (code === ERROR_CODE.TEACHER__RECOVER_PASSWORD__CODE_ALREADY_SENT) {
          setRecoveringPasswordError(`Ya te enviamos un correo para resetear un contraseña. En caso de no recibirlo vuele a intentarlo en 10 minutos`)
          return
        }
      }
      setRecoveringPasswordError(`Error interno, inténtalo más tarde`)
    } finally {
      setIsLoading(false)
    }
  }

  const containerSx = { marginTop: `16px` }

  return (
    <Grid container>
      <Grid lg={areTwoComponents ? 6 : 12} md={12} sm={12} xs={12} >
        <Box sx={{ padding: `20px 20px 0 20px` }}>
          <Logo />
        </Box>

        <Box
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            flexDirection: `column`,
            padding: `50px 0 50px 0`,
            minHeight: `calc(100vh - 45px - 25px - 100px)`
          }}
        >
          <Container maxWidth={false} sx={{ maxWidth: `480px` }} >
            <Container disableGutters sx={{ marginBottom: `40px` }}>
              <Typography textAlign="center" fontSize="36px" fontWeight="700">
                {isLoggingIn && `Bienvenido a InstruIA`}
                {isRegistering && `Regístrate`}
                {isConfirmatingRegistration && `Confirma tu registro`}
                {(isResettingPassword || isConfirmatingPasswordReset) && `Recupera tu contraseña`}
              </Typography>
            </Container>

            {(isLoggingIn || isRegistering) && (
              <>
                <Container disableGutters sx={{ display: `flex`, justifyContent: `center` }} >
                  <GoogleLogin
                    onSuccess={onEnteredGoogle}
                    onError={() => {
                      console.log(`Login Failed`)
                    }}
                  />
                </Container>

                <Container disableGutters sx={{ marginTop: `24px`, marginBottom: `24px` }} >
                  <Divider >
                    <Typography textAlign="center" fontSize="14px" fontWeight="450" color="#7E8B99" >
                      {isLoggingIn ? `O ingresa con tu correo` : ` O continúa con tu correo `}
                    </Typography>
                  </Divider>
                </Container>
              </>
            )}

            {isLoggingIn && (
              <>
                <Container disableGutters sx={containerSx} >
                  <_Label value="Email" />
                  <InTextField
                    id='login-email'
                    error={loginEmailError}
                    placeholder="Ingresa tu correo"
                    type='email'
                    isDisabled={isLoading}
                    value={email}
                    onChange={(v) => {
                      if (loginEmailError !== ``) setLoginEmailError(``)
                      if (loginError) setLoginError(``)
                      setEmail(v)
                    }}
                    onEnter={() => {
                      if (email === ``) return
                      if (password === ``) return
                      onLogin()
                    }}
                  />
                </Container>

                <Container disableGutters sx={containerSx} >
                  <_Label value="Contraseña" />
                  <InTextField
                    id='login-password'
                    placeholder="Contraseña"
                    type='password'
                    isDisabled={isLoading}
                    value={password}
                    onChange={(v) => {
                      if (loginError) setLoginError(``)
                      setPassword(v)
                    }}
                    onEnter={() => {
                      if (email === ``) return
                      if (password === ``) return

                      onLogin()
                    }}
                  />
                </Container>

                <Container disableGutters sx={containerSx}>
                  <Typography
                    sx={{ fontSize: `14px`, ...textLinkStyle }}
                    onClick={() => {
                      setStage(STAGE.RESET_PASSWORD)
                    }}>
                    ¿Olvidaste la contraseña?
                  </Typography>
                </Container>

                <Container disableGutters sx={{ marginTop: `24px` }} >
                  <Button
                    id='button-login'
                    fullWidth
                    variant='contained'
                    disabled={isLoading || email === `` || password === ``}
                    onClick={onLogin}
                  >
                    Iniciar sesión
                  </Button>
                </Container>

                {loginError !== `` && (
                  <Container disableGutters sx={{ marginTop: `15px`, display: `flex`, justifyContent: `center` }} >
                    <Typography
                      color="red"
                    >
                      {loginError}
                    </Typography>
                  </Container>
                )}
              </>
            )}

            {isRegistering && (
              <>
                <Box style={{ display: `flex`, flexFlow: `row wrap`, gap: `16px`, width: `100%` }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <_Label value="Nombre" />
                    <InTextField
                      id="register-firstname"
                      error={registeringFirstNameError}
                      placeholder="Nombre"
                      type='text'
                      isDisabled={isLoading}
                      value={registeringFirstName}
                      onChange={(v) => {
                        if (registeringFirstNameError !== ``) setRegisteringFirstNameError(``)
                        if (registeringError !== ``) setRegisteringError(``)
                        setRegisteringFirstName(v)
                      }}
                    />
                  </Box>

                  <Box sx={{ flexGrow: 1 }}>
                    <_Label value="Apellido" />
                    <InTextField
                      id="register-lastname"
                      error={registeringLastNameError}
                      placeholder="Apellido"
                      type='text'
                      isDisabled={isLoading}
                      value={registeringLastName}
                      onChange={(v) => {
                        if (registeringLastNameError !== ``) setRegisteringLastNameError(``)
                        if (registeringError !== ``) setRegisteringError(``)
                        setRegisteringLastName(v)
                      }}
                    />
                  </Box>
                </Box>

                <Container disableGutters sx={containerSx} >
                  <_Label value="Email" />
                  <InTextField
                    id="register-email"
                    error={registeringEmailError}
                    placeholder="Ingresa tu correo"
                    type='email'
                    isDisabled={isLoading}
                    value={registeringEmail}
                    onChange={(v) => {
                      if (registeringEmailError !== ``) setRegisteringEmailError(``)
                      if (registeringError !== ``) setRegisteringError(``)

                      setRegisteringEmail(v)
                    }}
                  />
                </Container>

                <Container disableGutters sx={containerSx} >
                  <_Label value="Crea tu contraseña" />
                  <InTextField
                    id='register-password'
                    error={registeringPasswordError}
                    placeholder="Crea tu Contraseña"
                    type='password'
                    isDisabled={isLoading}
                    value={registeringPassword}
                    onChange={(v) => {
                      if (registeringPasswordError !== ``) setRegisteringPasswordError(``)
                      if (registeringError !== ``) setRegisteringError(``)
                      setRegisteringPassword(v)
                    }}
                  />
                </Container>

                <Container disableGutters sx={{ marginTop: `5px`, marginLeft: `5px` }} >
                  <Typography fontWeight="400" fontSize="12px" >
                    Mínimo 8 caracteres
                  </Typography>
                </Container>

                <Container disableGutters sx={containerSx} >
                  <_Label value="Confirma tu contraseña" />
                  <InTextField
                    id='register-passwordConfirmation'
                    error={registeringPasswordConfirmationError}
                    placeholder="Confirma tu contraseña"
                    type='password'
                    isDisabled={isLoading}
                    value={registeringPasswordConfirmation}
                    onChange={(v) => setRegisteringPasswordConfirmation(v)}
                  />
                </Container>

                <Container disableGutters sx={{ marginTop: `30px` }} >
                  <Button
                    id="button-register"
                    fullWidth
                    variant='contained'
                    disabled={getIsRegisterButtonDisabled()}
                    onClick={onRegisterWithEmail}
                  >
                    Registrarme
                  </Button>
                </Container>

                {registeringError !== `` && (
                  <Container disableGutters sx={{ marginTop: `15px`, display: `flex`, justifyContent: `center` }} >
                    <Typography color="red">
                      {registeringError}
                    </Typography>
                  </Container>
                )}
              </>
            )}

            {(isLoggingIn || isRegistering) && (
              <Container disableGutters sx={{ marginTop: `30px`, display: `flex`, justifyContent: `space-around` }} >
                <Typography sx={{ fontWeight: `700`, fontSize: `16px`, color: `#7E7E7E` }}>
                  {isLoggingIn ? `¿No tienes cuenta?` : `¿Ya tienes cuenta?`}
                </Typography>

                <Typography
                  id="call-to-action"
                  sx={{ fontSize: `16px`, ...textLinkStyle }}
                  onClick={() => {
                    if (isLoggingIn) {
                      if (email !== ``) setEmail(``)
                      if (password !== ``) setPassword(``)
                      if (loginEmailError) setLoginEmailError(``)

                      setStage(STAGE.REGISTER)
                      return
                    }

                    if (registeringFirstName !== ``) setRegisteringFirstName(``)
                    if (registeringLastName !== ``) setRegisteringLastName(``)
                    if (registeringEmail !== ``) setRegisteringEmail(``)
                    if (registeringPassword !== ``) setRegisteringPassword(``)
                    if (registeringPasswordConfirmation !== ``) setRegisteringPasswordConfirmation(``)

                    setStage(STAGE.LOGIN)
                  }}
                >
                  {isLoggingIn ? `Regístrate gratis` : `Inicia sesión`}
                </Typography>
              </Container>
            )}

            {isConfirmatingRegistration && (
              <>
                <Container disableGutters >
                  <Typography fontSize="16px" fontWeight="300" >
                    Enviamos un link de confirmación a tu correo electrónico.
                  </Typography>
                </Container>

                <Container disableGutters sx={{ marginTop: `30px` }} >
                  <Button
                    fullWidth
                    variant='contained'
                    disabled={isLoading}
                    onClick={() => setStage(STAGE.LOGIN)}
                  >
                    Atrás
                  </Button>
                </Container>
              </>
            )}

            {isResettingPassword && (
              <>
                <Container disableGutters >
                  <Typography fontSize="16px" fontWeight="300" textAlign="justify">
                    Ingresa tu correo, te enviaremos instrucciones para resetear tu contraseña
                  </Typography>
                </Container>

                <Container disableGutters sx={{ marginTop: `20px` }} >
                  <InTextField
                    id='resetPassword-email'
                    error={recoveringEmailError}
                    placeholder="Email"
                    type='email'
                    isDisabled={isLoading}
                    value={recoveringEmail}
                    onChange={(v) => {
                      if (recoveringEmailError !== ``) setRecoveringEmailError(``)
                      if (recoveringPasswordError !== ``) setRecoveringPasswordError(``)

                      setRecoveringEmail(v)
                    }}
                    onEnter={() => {
                      onRecoverPassword()
                    }}
                  />
                </Container>

                {recoveringPasswordError !== `` && (
                  <Container disableGutters sx={{ marginTop: `10px` }} >
                    <Typography color="red">
                      {recoveringPasswordError}
                    </Typography>
                  </Container>
                )}

                <Container disableGutters sx={{ marginTop: `30px`, display: `flex`, justifyContent: `space-around`, alignItems: `center` }} >
                  <Typography
                    textAlign="center"
                    sx={{ marginLeft: `10px`, flexGrow: 1, ...textLinkStyle }}
                    onClick={() => {
                      if (recoveringEmail === ``) setRecoveringEmail(``)
                      if (recoveringEmailError === ``) setRecoveringEmailError(``)
                      if (recoveringPasswordError === ``) setRecoveringPasswordError(``)
                      setStage(STAGE.LOGIN)
                    }}
                  >
                    Volver al inicio
                  </Typography>

                  <Button
                    variant='contained'
                    disabled={recoveringEmail === `` || isLoading}
                    onClick={onRecoverPassword}
                    sx={{ flexGrow: 1 }}
                  >
                    Siguiente
                  </Button>
                </Container>
              </>
            )}

            {isConfirmatingPasswordReset && (
              <>
                <Container disableGutters >
                  <Typography fontSize="16px" fontWeight="300" textAlign="justify">
                    Enviamos instrucciones a tu correo electrónico para resetear tu contraseña.
                  </Typography>
                </Container>

                <Container disableGutters sx={{ marginTop: `30px` }} >
                  <Button
                    fullWidth
                    variant='contained'
                    disabled={isLoading}
                    onClick={() => setStage(STAGE.LOGIN)}
                  >
                    Atrás
                  </Button>
                </Container>
              </>
            )}

          </Container>
        </Box>

      </Grid>

      {
        areTwoComponents && (
          <Grid lg={6} md={12} sm={12} xs={12} sx={{ backgroundColor: `secondary.main`, height: `100vh`, display: `flex`, justifyContent: `center`, alignItems: `center` }}>
            <img src={Circle} width="400" height="300" />
          </Grid>
        )
      }

    </Grid >

  )
}

interface IProps {
  value: string
}
function _Label (props: IProps) {
  return (
    <Typography fontSize="16px" fontWeight="700" sx={{ marginBottom: `8px` }}>
      {props.value}
    </Typography>
  )
}

export default Login
