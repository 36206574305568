import React from 'react'

import { ITeacher } from 'src/entities/teacher/types'
import { Action, Dispatch, State } from './types'

const TeacherStateContext = React.createContext<State | undefined>(undefined)
const TeacherDispatchContext = React.createContext<Dispatch | undefined>(undefined)

const teacherReducer = (state: State, action: Action) => {
  switch (action.type) {
    case `TEACHER.SET_TEACHER`: {
      return {
        ...state,
        teacher: action.teacher
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

const TeacherProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(
    teacherReducer, {
      teacher: null
    }
  )
  return (
    <TeacherStateContext.Provider value={state}>
      <TeacherDispatchContext.Provider value={dispatch}>
        {props.children}
      </TeacherDispatchContext.Provider>
    </TeacherStateContext.Provider>
  )
}

const useTeacherState = () => {
  const context = React.useContext(TeacherStateContext)
  if (context === undefined) {
    throw new Error(`useTeacherState must be used within a TeacherProvider`)
  }
  return context
}

const useTeacherDispatch = () => {
  const context = React.useContext(TeacherDispatchContext)
  if (context === undefined) {
    throw new Error(`useTeacherDispatch must be used within a TeacherProvider`)
  }
  return context
}

export { TeacherProvider, useTeacherState, useTeacherDispatch }

export const setTeacher = (dispatch: Dispatch, teacher: ITeacher | null) => {
  dispatch({ type: `TEACHER.SET_TEACHER`, teacher })
}
