import React from 'react'

import TextField from 'src/components/text-field'

interface IProps {
  isLoading: boolean
  additionalDetails: string
  setAdditionalDetails: (s: string) => void
  onContinueStep?: () => void
  onSkipStep?: () => void
}
export default function AdditionalDetailsStep4 (props: IProps) {
  return (
    <TextField
      placeholder="Detalles adicionales sobre cómo deseas tu plan de clase"
      isDisabled={props.isLoading}
      value={props.additionalDetails}
      onChange={props.setAdditionalDetails}
      multiline
      minRows={4}
      maxRows={10}
      onShiftEnter={() => {
        if (props.additionalDetails === ``) {
          if (props.onSkipStep !== undefined) {
            props.onSkipStep()
            return
          }
        }

        if (props.onContinueStep !== undefined) {
          props.onContinueStep()
        }
      }}
    />
  )
}
