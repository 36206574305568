import { Box, Typography } from '@mui/material'
import React from 'react'

interface IProps {
  icon: any
  title: string
  subtitle: string
}
export default function Title (props: IProps) {
  return (
    <Box sx={{ display: `flex`, flexFlow: `row nowrapp`, gap: `20px` }}>
      <img src={props.icon} height="70" width="70" />

      <Box sx={{ display: `flex`, flexDirection: `column` }}>
        <Typography fontSize="24px" fontWeight="600">
          {props.title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {props.subtitle}
        </Typography>
      </Box>

    </Box>
  )
}
