import React, { Fragment, useEffect, useState } from 'react'

import * as teacherService from 'src/entities/teacher/service'

import { useTeacherDispatch, setTeacher } from '../../context/teacher'

import * as CONSTANTS from 'src/config/constants'

import * as analytics from '../../modules/analytics'
import MobileAppbar from './mobile-appbar'
import { useIsMobile } from 'src/modules/utils'

interface LogicProps {
  children: React.ReactNode
}

const Logic = (props: LogicProps) => {
  const isMobile = useIsMobile()
  const teacherDispatch = useTeacherDispatch()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function loadSession () {
      try {
        const token = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.JWT)

        if (token === null) {
          localStorage.clear()
          return
        }

        const response = await teacherService.loginWithToken()
        localStorage.setItem(CONSTANTS.LOCAL_STORAGE.JWT, response.token)
        analytics.identifyTeacher(response)

        setTeacher(teacherDispatch, response.teacher)
      } catch (e) {
        console.error(`session load error`, e)
        localStorage.clear()
      } finally {
        setIsLoading(false)
      }
    }
    loadSession()
  }, [])

  if (isLoading) {
    return (
      <div>
        Loading
      </div>
    )
  }

  const shouldRenderAppbar = isMobile && location.pathname !== `/login`

  return (
    <Fragment>
      {shouldRenderAppbar && <MobileAppbar></MobileAppbar>}
      {props.children}
    </Fragment>
  )
}

export default Logic
