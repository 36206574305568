
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const textLinkStyle = { cursor: `pointer`, color: `primary.main`, fontWeight: `600` }

export function useIsMobile () {
  const stylesTheme = useTheme()
  const isMobile = !useMediaQuery(stylesTheme.breakpoints.up(`md`))

  return isMobile
}
