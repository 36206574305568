import { ERROR_CODE } from '@instruia/utils'
import { Modal, Box, Typography } from '@mui/material'
import React from 'react'

interface IProps {
  executionErrorCode: ERROR_CODE.TEACHER__EXECUTE_WORKSHEET__MAX_QUOTA_REACHED | null
  setExecutionErrorCode: ((c: ERROR_CODE.TEACHER__EXECUTE_WORKSHEET__MAX_QUOTA_REACHED | null) => void)
}

const PADDING = 20

export default function ExecutionErrorModal (props: IProps) {
  return (
    <Modal
      open={props.executionErrorCode !== null}
      onClose={() => props.setExecutionErrorCode(null)}
    >
      <Box
        sx={{
          position: `absolute`,
          top: `calc(50% - ${PADDING}px)`,
          left: `calc(50% - ${PADDING}px)`,
          transform: `translate(-50%, -50%)`,
          width: `calc(100% - 80px)`,
          maxWidth: `650px`,
          margin: `${PADDING}px`,
          bgcolor: `background.paper`,
          borderRadius: `8px`,
          padding: `${PADDING}px`
        }}
      >
        <Typography textAlign="center" fontWeight="600" fontSize="24px" >
          Error
        </Typography>
        <Typography textAlign="justify" sx={{ marginTop: `20px` }}>
          Has alcancado el número máximo de ejecuciones en 24 horas que puede realizar un usuario.
          <br></br><br></br>
          Si deseas extender este límite envía un correo a <a target='_blank' href='mailto:juanda@instruia.com'>juanda@instruia.com</a>
        </Typography>
      </Box>
    </Modal>
  )
}
