import { SCHOOL_SUBJECT, mapSubjectCodeIntoTitle } from '@instruia/utils'
import { Select, FormControl, MenuItem } from '@mui/material'
import React from 'react'

const SUBJECTS = [
  SCHOOL_SUBJECT.ART,
  SCHOOL_SUBJECT.VISUAL_ART,
  SCHOOL_SUBJECT.BIOLOGY,
  SCHOOL_SUBJECT.NATURAL_SCIENCES,
  SCHOOL_SUBJECT.SOCIAL_SCIENCES,
  SCHOOL_SUBJECT.SCIENCE_AND_TECHNOLOGY,
  SCHOOL_SUBJECT.PHYSICAL_EDUCATION,
  SCHOOL_SUBJECT.SOCIOEMOTIONAL_EDUCATION,
  SCHOOL_SUBJECT.SPANISH,
  SCHOOL_SUBJECT.ETHICS,
  SCHOOL_SUBJECT.GEOGRAPHY,
  SCHOOL_SUBJECT.HISTORY,
  SCHOOL_SUBJECT.ENGLISH,
  SCHOOL_SUBJECT.MATH,
  SCHOOL_SUBJECT.MUSIC,
  SCHOOL_SUBJECT.TEATHRE,
  SCHOOL_SUBJECT.TECHNOLOGY,
  SCHOOL_SUBJECT.HEALTHY_LIFE
]

interface IProps {
  isDisabled: boolean
  subject: SCHOOL_SUBJECT | null
  setSubject: (g: SCHOOL_SUBJECT) => void
}
export default function SelectSubjectStep1 (props: IProps) {
  return (
    <FormControl fullWidth>
      <Select
        sx={{
          backgroundColor: `#FFFFFF`,
          borderRadius: `8px`,
          border: `1px`,
          ".MuiSelect-icon": { color: `primary.main` }
        }}
        disabled={props.isDisabled}
        value={props.subject !== null ? props.subject : `Selecciona una materia`}
        onChange={(e) => {
          const v = e.target.value
          const gradeToSet = SUBJECTS.find(g => g === v) || null
          if (gradeToSet === null) return
          props.setSubject(gradeToSet)
        }}
      >
        {SUBJECTS.map(g => {
          return (
            <MenuItem
              key={g}
              value={g}
            >
              {mapSubjectCodeIntoTitle(g)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
