import { Box, Button, Container, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import * as teacherService from '../../entities/teacher/service'

import { ERROR_CODE } from '@instruia/utils'

import Logo from '../../components/logo'

const ERROR_CODE_TRY_AGAIN = `ERROR_CODE_TRY_AGAIN`

function RegistrationConfirmation () {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const paramsCode = searchParams.get(`code`)

  const [isLoading, setIsLoading] = useState(true)
  const [errorCode, setErrorCode] = useState<ERROR_CODE | null | `ERROR_CODE_TRY_AGAIN`>(null)

  useEffect(() => {
    // http://localhost:5056/registration-confirmation?code=omPCnh16NAdcvqPzJJBn
    if (paramsCode === null || paramsCode === ``) {
      navigate(`/login`)
      return
    }
    validateCode(paramsCode)
  }, [])

  async function validateCode (code: string) {
    try {
      await teacherService.confirmRegistration(code)
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)

      if (e.response.data !== undefined && e.response.data.code !== undefined) {
        const code = e.response.data.code
        if (code === ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__CODE_NO_EXIST) setErrorCode(ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__CODE_NO_EXIST)
        else if (code === ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__ALREADY_CONFIRMED) setErrorCode(ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__ALREADY_CONFIRMED)
        else if (code === ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__CODE_EXPIRED) setErrorCode(ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__CODE_EXPIRED)
        else setErrorCode(ERROR_CODE_TRY_AGAIN)
        return
      }
      setErrorCode(ERROR_CODE_TRY_AGAIN)
    }
  }

  function getErrorMessage () {
    if (errorCode === ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__CODE_NO_EXIST) {
      return `Link inválido, inténtalo de nuevo`
    }
    if (errorCode === ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__ALREADY_CONFIRMED) {
      return `Ya esta cuenta fue confirmada, inicia sesión con tus credenciales`
    }
    if (errorCode === ERROR_CODE.TEACHER__CONFIRM_REGISTRATION__CODE_EXPIRED) {
      return `El link expiró, inicia el registro de nuevo`
    }
    return `Error desconocido, intenta más tarde`
  }

  if (isLoading) {
    return (
      <Container sx={{ marginTop: `10px`, padding: `0px 0px 0px 0px` }}>
        <Typography fontSize="22px">
          Cargando
        </Typography>
      </Container>
    )
  }

  const errorMessage = getErrorMessage()

  return (
    <Box>
      <Logo />
      <Box sx={{
        display: `flex`,
        flexFlow: `row wrap`,
        justifyContent: `center`,
        alignItems: `center`,
        width: `100%`,
        minHeight: `calc(100vh - 45px - 25px - 100px)`
      }}>

        <Box sx={{ padding: `50px 20px 50px 20px`, maxWidth: `480px`, flexGrow: 1 }}>
          <Box sx={{ marginBottom: `40px` }}>
            <Typography fontSize="36px" fontWeight="700" textAlign="center" >
              {errorCode === null ? `Registro exitoso` : `Error`}
            </Typography>
          </Box>
          <Box >

            <Typography fontSize="16px" fontWeight="300" textAlign="justify">
              {errorCode === null ? `Cuenta confirmada. ¡Inicia sesión con tus credenciales!` : errorMessage}
            </Typography>

          </Box>
          <Box sx={{ marginTop: `30px` }}>
            <Button
              id='button-go-to-login'
              fullWidth
              variant='contained'
              onClick={() => navigate(`/login`)}
            >
              {errorCode === null ? `Iniciar sesión` : `Regresar`}
            </Button>
          </Box>
        </Box>

      </Box>

    </Box>
  )
}

export default RegistrationConfirmation
