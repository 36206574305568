import React from 'react'

import Back from './back'
import Title from './title'
import ProgressBar from './progress-bar'

interface IProps {
  percentage: number
  isDone: boolean
  shouldDisplayBackButton: boolean
  onBack: () => void
  isLoading: boolean
  icon: any
  title: string
  subtitle: string
}
export default function Header (props: IProps) {
  return (
    <>
      <Back shouldDisplayBackButton={props.shouldDisplayBackButton} onBack={props.onBack} />
      <Title icon={props.icon} title={props.title} subtitle={props.subtitle} />
      {(!props.isDone || props.isLoading) && (
        <ProgressBar percentage={props.percentage} isDone={props.isDone} />
      )}
    </>
  )
}
