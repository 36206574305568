import React from 'react'

import { Box, TextField, IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

interface IProps {
  isDisabled?: boolean
  placeholder: string
  value: string
  setValue: (v: string) => void
  onSubmit: () => void
}

export default function ChatBar (props: IProps) {
  return (
    <Box
      sx={{
        backgroundColor: `#FFFFFF`,
        width: `100%`,
        borderRadius: `2px`,
        display: `flex`,
        flexDirection: `row`,
        alignItems: `center`,
        boxShadow: `0px 8px 24px -4px #00000014`
      }}
    >
      <TextField
        sx={{
          flexGrow: 1,
          "& .MuiInputBase-root": {
            padding: `0 8px 0 8px`
          },
          "& .MuiOutlinedInput-root:not(.Mui-focused)": {
            "&.MuiInputBase-root fieldset": {
              border: `none`
            }
          }
        }}
        InputProps={{
          sx: { borderColor: `red` },
          endAdornment: (
            <IconButton disabled={props.isDisabled === true || props.value === null || props.value === ``} onClick={props.onSubmit} >
              <SendIcon color='primary' />
            </IconButton>)
        }}
        disabled={props.isDisabled === true}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => {
          if (props.isDisabled === true) return
          const v = e.target.value
          props.setValue(v)
        }}
      />

    </Box>
  )
}
