import { SCHOOL_GRADE, SCHOOL_SUBJECT, mapSubjectCodeIntoTitle, mapGradeCodeIntoTitle } from '@instruia/utils'
import { Box, Typography } from '@mui/material'
import React from 'react'
import ExecutedLessonCard from './card'
import * as utils from 'src/modules/utils'
import EditNoteIcon from '@mui/icons-material/EditNote'

interface IProps {
  isLoading: boolean
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
  pronouns: string
  setPronouns: (s: string) => void
  strengths: string
  setStrengths: (s: string) => void
  opportunities: string
  setOpportunities: (o: string) => void
  onEditStudentReportClicked: () => void
}
export default function ExecutedLesson (props: IProps) {
  return (
    <Box sx={{ display: `flex`, flexDirection: `column`, gap: `20px`, alignItems: `stretch` }}>
      <Box sx={{ display: `flex`, justifyContent: `space-between` }}>
        <Typography fontSize="20px" fontWeight="600">
          Resumen
        </Typography>
        <Box
          onClick={props.onEditStudentReportClicked}
          sx={{ display: `flex`, gap: `4px`, cursor: `pointer` }}
        >
          <EditNoteIcon color='primary' />
          <Typography
            textAlign="end"
            sx={{ fontSize: `14px`, ...utils.textLinkStyle }}
          >
            Editar
          </Typography>
        </Box>
      </Box>
      <ExecutedLessonCard title='Grado' content={props.grade !== null ? mapGradeCodeIntoTitle(props.grade) : ``} />
      <ExecutedLessonCard title='Pronombres' content={props.pronouns === null || props.pronouns === `` ? `No especifica` : props.pronouns} />
      <ExecutedLessonCard title='Fortalezas' content={props.strengths === null || props.strengths === `` ? `No especifica` : props.strengths} />
      <ExecutedLessonCard
        title='Oportunidades de mejora'
        content={props.opportunities === null || props.opportunities === `` ? `No especifica` : props.opportunities} />
    </Box>
  )
}
