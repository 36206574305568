import { Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotFoundPage () {
  const navigate = useNavigate()

  return (
    <div>
      <Typography variant='h2'>La página que buscas no existe</Typography>
      <Button variant='contained' onClick={() => navigate(`/`)} >Volver</Button>
    </div>
  )
}
