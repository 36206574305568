import React from 'react'
import { createRoot } from 'react-dom/client'

import * as Sentry from "@sentry/react"
import AppSetup from './app-setup'

import * as CONFIG from './config'

const rootElement = document.querySelector(`#root`)
if (!rootElement) throw new Error(`Failed to find the root element`)

if (CONFIG.SENTRY_DSN !== null) {
  console.log(`ENTROO`)
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [`localhost`, /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = createRoot(rootElement)

root.render(
  <AppSetup />
)
