import { SCHOOL_GRADE } from '@instruia/utils'
import React from 'react'
import SelectGrade from 'src/components/select-grade'

interface IProps {
  isDisabled: boolean
  grade: SCHOOL_GRADE | null
  setGrade: (g: SCHOOL_GRADE) => void
}
export default function SelectGradeStep2 (props: IProps) {
  return (
    <SelectGrade
      isDisabled={props.isDisabled}
      grade={props.grade}
      setGrade={props.setGrade}
    />
  )
}
